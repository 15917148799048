import axios from "axios";
import React, { useEffect, useState } from "react";
import { NotificationManager } from 'react-notifications';
import { Link, useLocation, useNavigate } from "react-router-dom";
import imursifLogo from '../../assets/images/imursif_with_white_text.png';
import Loader from "../../components/home/common/Loader";
import APP_ROUTES from "../../helper/app-routes";
import DEVELOPMENT_CONFIG from "../../helper/config";
import { base_url, getChargeDetails, redirectURLLogin, SCOPES, SHOPIFY_API_KEY } from "../config/config";


function LogIn() {
    const navigate = useNavigate();
    const location = useLocation()
    const [errors, setErrors] = useState("");
    const [fields, setFields] = useState({
        username: "",
        password: "",
    })
    const [indicator, setIndicator] = useState(false)


    //Input field values

    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value
        })
    }

    //validation for username and password
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!fields.username || fields.username.trim().length === 0) {
            formIsValid = false;
            errors["username"] = "username can't be blank"

        }

        if (!fields.password || fields.password.trim().length === 0) {
            formIsValid = false;
            errors["password"] = "password can't be blank"
        }
        setErrors(errors)
        return formIsValid;
    }

    //check subscription
    const getSubscription = () => {

        let chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID);

        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))

        let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)

        let shopName = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)


        setIndicator(true)
        var data = JSON.stringify({
            "shopify_token": shopifyToken,
            "charge_id": chargeId,
            "shop_name": shopName,
            "user_id": loginInfo.id
        });

        var config = {
            method: 'post',
            url: getChargeDetails,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let shopifyURL = "https://" + shopName + "/admin/oauth/authorize?client_id=" + SHOPIFY_API_KEY + "&scope=" + SCOPES + "&state=" + 'nonce' + "&redirect_uri=" + redirectURLLogin;
                window.location.replace(shopifyURL)
                navigate(APP_ROUTES.DASHBOARD)
            })
            .catch(function (error) {
                setIndicator(false)

            });
    }

    //Login api 

    const saveLogIn = () => {
        if (fields.username.trim() !== '' && fields.password.trim() !== '') {
            setIndicator(true);
            var dataForm = JSON.stringify({
                query: `mutation{
                    login(
                        username:"${fields.username}"
                        password:"${fields.password}"
                    ){
                        token
                        email
                        name
                        lastName
                        username
                        id
                        brandCode
                        shopifyApiInfo{
                            storeSubdomain
                            apiKey
                            apiSecret
                            accessToken
                            chargeId
                            billingOnDate
                            trial_start
                            trial_end
                            subscription_start
                            subscription_end
                            package
                            is_subscribed
                            is_shopify_login
                        }
                  
                    }
                  }`,
                variables: {},
            });

            var config = {
                method: 'post',
                url: base_url,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: dataForm,
            };

            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.data === null) {
                        const text = json.errors[0].message;
                        NotificationManager.error("Error", text, 800)
                    }
                    if (json.data  !== null) {
                        const jsonData = json.data.login;
                        localStorage.setItem(DEVELOPMENT_CONFIG.TOKEN, jsonData.token)
                        localStorage.setItem(DEVELOPMENT_CONFIG.SHOP, jsonData.shopifyApiInfo.storeSubdomain)
                        localStorage.setItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN, jsonData.shopifyApiInfo.accessToken)
                        localStorage.setItem(DEVELOPMENT_CONFIG.CHARGE_ID, jsonData.shopifyApiInfo.chargeId)
                        localStorage.setItem(DEVELOPMENT_CONFIG.LOGIN_INFO, JSON.stringify(jsonData))
                        localStorage.setItem(DEVELOPMENT_CONFIG.BILLING_ON, jsonData.shopifyApiInfo.billingOnDate)
                        localStorage.setItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED, jsonData.shopifyApiInfo.is_subscribed)
                        localStorage.setItem(DEVELOPMENT_CONFIG.IS_SHOPIFY_LOGIN, jsonData.shopifyApiInfo.is_shopify_login)
                        getSubscription()
                    }
                    setIndicator(false);
                })
                .catch(function (error) {
                    NotificationManager.error("Some Error Occurred")
                    setIndicator(false);
                });
        } else {
            setIndicator(false);
        };

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            saveLogIn();
        }
    }


    //clear local storage on logout

    useEffect(() => {
        localStorage.removeItem("dropdown")
        localStorage.removeItem("videoUrlId")
        localStorage.removeItem(DEVELOPMENT_CONFIG.IS_SHOPIFY_LOGIN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)
        localStorage.removeItem(DEVELOPMENT_CONFIG.LOGIN_INFO)
        localStorage.removeItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.BILLING_ON)
        localStorage.removeItem(DEVELOPMENT_CONFIG.CHARGE_ID)
        localStorage.removeItem("ItemList")
        localStorage.removeItem("finalVideoData")
        localStorage.removeItem(DEVELOPMENT_CONFIG.DAYS)
        localStorage.removeItem(DEVELOPMENT_CONFIG.TOKEN)
        localStorage.removeItem("thumbnail")
        localStorage.removeItem("videoUrl")
    }, [])

    //Login Layout
    return (
        <div className=" registeration-page login-page">

            <div className=" container text-center ">
                <img src={imursifLogo} className="logo" />
                <div className="center-block">
                    <div className="row h-100 w-100 justify-content-center">
                        {indicator && <Loader />}
                        <div className="col-lg-6 col-md-7 col-12 px-md-3 px-0">
                            <h2 className="text-left mb-4">Log In</h2>

                            <div className="form-block mx-auto">
                                <p style={{ fontSize: 14, textAlign: 'left', marginBottom: 15 }}>Please enter username and password sent to you by email</p>
                                <input
                                    value={fields.username}
                                    onChange={handleOnChange}
                                    type="text"
                                    required
                                    name="username"
                                    placeholder="Username"
                                    className={errors.username ? "input-control mb-4 invalid-input" : "input-control mb-4"} />
                                <input
                                    onChange={handleOnChange}
                                    value={fields.password}
                                    type="password"
                                    required
                                    name="password"
                                    placeholder="Password"
                                    className={errors.password ? "input-control mb-4 invalid-input" : "input-control mb-4"} />

                                <button onClick={handleSubmit} className="btn-save border-0">Login</button>
                                <p style={{ textAlign: 'center', marginTop: '20px' }}> Don't have an account? <Link
                                    to={APP_ROUTES.REGISTER} className="link"> Signup</Link></p>

                                {/* The video name issue is resolved in 1.6(75) (build number on register) */}
                                <h3 className="text-center mt-10">v {process.env.REACT_APP_VERSION}</h3>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default LogIn;
