import React, {useEffect, useState} from 'react';
import imursif from '../../assets/images/imursif_with_white_text.png'
import APP_ROUTES from "../../helper/app-routes";
import {useNavigate} from "react-router-dom";
import DEVELOPMENT_CONFIG from "../../helper/config";
import {renewRedirectAPI, buySubscriptionUrl, getChargeDetails} from "../config/config";
import axios from "axios";

import {NotificationManager} from "react-notifications";
import Loader from "../../components/home/common/Loader";

const Profile=()=>{

    let loginDetail = localStorage.getItem("login_info");
    let logInfo = JSON.parse(loginDetail)
    const [isSubscribed, setIsSubscribed] = useState(0)
    const [subscription, setSubscription] = useState(null)
    const navigate = useNavigate();
    const [indicator, setIndicator] = useState(false)




    const getSubscription = () => {
        let chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID);
        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        let subscribed = localStorage.getItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)
        let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN);
        let shopName = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP);


        setIndicator(true)
        var data = JSON.stringify({
            "shopify_token": shopifyToken,
            "charge_id": chargeId,
            "shop_name": shopName,
            "user_id": loginInfo.id
        });

        var config = {
            method: 'post',
            url: getChargeDetails,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                const subscription1 = response.data.body
                setSubscription(subscription1)
                setIsSubscribed(subscribed)
                setIndicator(false)
            })
            .catch(function (error) {
                setIndicator(false)
                console.log(error);
            });


    }

    const buySubscription = (packageParam, replace) => {
        localStorage.setItem(DEVELOPMENT_CONFIG.PACKAGE, packageParam)
        let accessToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)
        setIndicator(true)
        if (replace === true) {
            var data = JSON.stringify({
                "shopify_token": accessToken,
                "user_id": loginDetail.id,
                "package_details": {
                    "name": packageParam,
                    "returnUrl": renewRedirectAPI,
                    "replacementBehaviour": "APPLY_IMMEDIATELY",
                    "trialDays": 0,
                    "lineItems": [
                        {
                            "plan": {
                                "appRecurringPricingDetails": {
                                    "price": {
                                        "amount": packageParam === "Basic Monthly" ? 1 : 12,
                                        "currencyCode": "USD"
                                    },
                                    "interval": packageParam === "Basic Monthly" ? "EVERY_30_DAYS" : "ANNUAL"
                                }
                            }
                        }
                    ]
                },
                "shop_name": shop
            });
        } else {
            var data = JSON.stringify({
                "shopify_token": accessToken,
                "user_id": loginDetail.id,
                "package_details": {
                    "name": packageParam,
                    "returnUrl": renewRedirectAPI,
                    "trialDays": 0,
                    "lineItems": [
                        {
                            "plan": {
                                "appRecurringPricingDetails": {
                                    "price": {
                                        "amount": packageParam === "Basic Monthly" ? 1 : 12,
                                        "currencyCode": "USD"
                                    },
                                    "interval": packageParam === "Basic Monthly" ? "EVERY_30_DAYS" : "ANNUAL"
                                }
                            }
                        }
                    ]
                },
                "shop_name": shop
            });
        }

        var config = {
            method: 'post',
            url: buySubscriptionUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let confirmationUrl = response.data.body.data.appSubscriptionCreate.confirmationUrl
                console.log("confirmationUrl", confirmationUrl)
                window.location.replace(confirmationUrl)
                setIndicator(false)
            })
            .catch(function (error) {
                NotificationManager.error(error.message)
                setIndicator(false)
            });
    }


    return(
        indicator ? <Loader/> :
            <>
                <div className="wrapper">
                    <div className="header">
                        <h3 className="text-white text-center font-weight-normal headtitle-3">
                            My Profile
                        </h3>
                    </div>
                    <div className="content" style={{
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        padding: "0px 20px 20px 20px",
                    }}>
                        <div className="subscription-box">
                            <div className="subscription-info">
                                <h5 className="text-uppercase link"></h5>
                            <div className="col-12 px-0">
                                <div className="field">
                                    <p className="headtitle">Username</p>
                                    <p className="sub">
                                        {logInfo.username}
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="headtitle">Email</p>
                                    <p className="sub">
                                        {logInfo.email}
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="headtitle">First Name</p>
                                    <p className="sub">
                                        {logInfo.name}
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="headtitle">Last Name</p>
                                    <p className="sub">
                                        {logInfo.lastName}
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="headtitle">Store URL</p>
                                    <p className="sub">
                                        {logInfo.shopifyApiInfo.storeSubdomain}
                                    </p>
                                </div>
                                <div className="active-timing d-flex flex-wrap justify-content-between mb-3"></div>
                                <div className="text-center">
                                    {isSubscribed !== 0  ?
                                        <button type="button" onClick={subscription.recurring_application_charge.name === "Basic Monthly"
                                            ? buySubscription("Basic Monthly", true) : buySubscription("Basic Yearly", true)} className="btn btn-new-red mt-3 ">Renew Subscription</button> :
                                        <button type="button" onClick={() => {navigate(APP_ROUTES.SUBSCRIBER)}} className="btn btn-new-red mt-3 ">Subscribe</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
            </>
    )

}

export default Profile;