import React, {useEffect, useState} from "react";


const ButtonGroup = ({ buttons, active, onClick }) => {
    const [clickedId, setClickedId] = useState(-1);

    const handleClick = (event, id) => {
        setClickedId(id);
        onClick(event);
    };

    useEffect(() => {
        setClickedId(active);
    }, [active]);

    return (
        <div className={"customButtonGroup"}>
            {buttons.map((buttonLabel, i) => (
                <button
                    key={i}
                    name={buttonLabel}
                    onClick={(event) => handleClick(event, i)}
                    className={i === clickedId ? "customButton customButtonGroupActive" : "customButton"}
                >
                    {buttonLabel}
                </button>
            ))}
        </div>
    );
};

export default ButtonGroup;