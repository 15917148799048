import React, { useEffect, useState } from "react";
import imursif from "../../../assets/images/imursif_with_white_text.png";
import playButton from "../../../assets/images/play-button.png";
import appImage from "../../../assets/images/app-img.png";
import profile from "../../../assets/images/profile-img.png";
import helpdesk from "../../../assets/images/helpdesk.svg";
import logout from "../../../assets/images/logout.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
import APP_ROUTES from "../../../helper/app-routes";
import useWindowSize from "./useWindowSize";
import BookIcon from '../../../assets/images/book.png'
import DEVELOPMENT_CONFIG from "../../../helper/config";
const Sidemenu = () => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const [width, height] = useWindowSize();
  const menuItems = [
    {
      id: "1",
      name: "Create Video",
      route: APP_ROUTES.DASHBOARD,
      src: playButton,
    },


    {
      id: "2",
      name: "User Guide",
      route: APP_ROUTES.INSTALLER,
      src: BookIcon,
    },


    {
      id: "3",
      name: "My Subscription",
      route: APP_ROUTES.SUBSCRIBER,
      src: appImage,
    },
    {
      id: "4",
      name: "My Profile",
      route: APP_ROUTES.PROFILE,
      src: profile,
    },
    {
      id: "5",
      name: "Become an Affiliate",
      route: APP_ROUTES.BECOME_AFFILIATE,
      src: helpdesk,
      target: "_blank",
      href: "https://affiliate.imursif.com"
    },
    {
      id: "6",
      name: "Logout",
      route: APP_ROUTES.LOGIN,
      src: logout,
    },
  ];

  const handleRouteChange = (e,obj) => {
    localStorage.removeItem("videoUrl")
    localStorage.removeItem("ItemList")
    localStorage.removeItem("finalVideoData")
    localStorage.removeItem("thumbnail")
    setSelectedTab(obj.id);

    if (obj.target) {
      e.preventDefault();
      window.open(obj.href, obj.target)

    } else {

      if ( obj.id === "6") {
        localStorage.removeItem(DEVELOPMENT_CONFIG.TOKEN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.IS_SHOPIFY_LOGIN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)
        localStorage.removeItem(DEVELOPMENT_CONFIG.LOGIN_INFO)
        localStorage.removeItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.BILLING_ON)
        localStorage.removeItem(DEVELOPMENT_CONFIG.CHARGE_ID)
        localStorage.removeItem(DEVELOPMENT_CONFIG.PACKAGE)
        localStorage.removeItem("dropdown")
        localStorage.removeItem("cartItems")
        localStorage.removeItem(DEVELOPMENT_CONFIG.FIELDS)
        localStorage.removeItem("videoUrlId")
        localStorage.removeItem(DEVELOPMENT_CONFIG.DAYS)

      }

          navigate(obj.route);
          document.getElementById('collapseExample').classList.remove('show');
      }

  };

 const handleSidebarHide = () => {
   document.getElementById('collapseExample').classList.remove('show');
 }

  useEffect(() => {
    if (location.pathname === APP_ROUTES.DASHBOARD) {
      setSelectedTab("1")
    } else if (location.pathname === APP_ROUTES.SUBSCRIBER) {
      setSelectedTab("3")
    }
    else if (location.pathname === APP_ROUTES.INSTALLER) {
      setSelectedTab("2")
    }
    else if (location.pathname === APP_ROUTES.PROFILE) {
      setSelectedTab("4")
    }


  }, [])



  return (
    <aside className="sidebar" id="collapseExample" onMouseLeave={handleSidebarHide} style={{ display: width > 992 && 'block' }} >
      <div className="sidelogo">
        <i className="fa-solid fa-xmark d-lg-none d-block text-white hide-menu p-3" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
        </i>

      </div>
      <ul className="side-navbar">
        {menuItems.map((obj, index) => (
          <li
            onChange={(e) => handleRouteChange(e,obj)}
            onClick={(e) => handleRouteChange(e,obj)}
            key={index}
            className={selectedTab === obj.id ? "active" : ""}
          >
            <Link>
              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                <img src={obj.src} />
                <span style={{ textAlign: 'end' }}>{obj.name}</span>
              </div>
            </Link>

          </li>
        ))}
      </ul>
      <p style={{ position: 'absolute', bottom: 20, width: '100%', textAlign: 'center', color: '#fff' }}>Build {process.env.REACT_APP_VERSION} </p>
    </aside>
  );
};

export default Sidemenu;
