import axios from "axios";
import React, {useEffect, useState} from "react";
import {NotificationManager} from 'react-notifications';
import Loader from "../../components/home/common/Loader";
import DEVELOPMENT_CONFIG from "../../helper/config";
import {
    base_url,
    buySubscriptionUrl,
    cancelSubscriptionUrl,
    getChargeDetails,
    renewRedirectAPI
} from "../config/config";

const Subscribe = () => {

    const [fields, setFields] = useState({
        package: ''
    })


    const [subscription, setSubscription] = useState(null)


    const [loginDetail, setLoginDetail] = useState(JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO)))

    const [isSubscribed, setIsSubscribed] = useState(0)

    let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN);
    let shopName = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP);
    let chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID);
    // let billingOnDate = localStorage.getItem("billing_on")
    const [chargeIdOrg, setChargeId] = useState("")
    const [subsInfo, setSubsInfo] = useState()
    const [indicator, setIndicator] = useState(false)


    useEffect(() => {
        getSubscription()
    }, [])

    const getSubscription = () => {
        chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID);
        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        let subscribed = localStorage.getItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)

        if (subscribed !== "0") {
            setIndicator(true)
            var data = JSON.stringify({
                "shopify_token": shopifyToken,
                "charge_id": chargeId,
                "shop_name": shopName,
                "user_id": loginInfo.id
            });

            var config = {
                method: 'post',
                url: getChargeDetails,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    const subscription1 = response.data.body
                    setSubscription(subscription1)
                    setIsSubscribed(subscribed)
                    setIndicator(false)
                })
                .catch(function (error) {
                    setIndicator(false)
                    console.log(error);
                });
        }

    }

    const cancelSubscription = () => {
        chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID);
        setIndicator(true)
        var data = JSON.stringify({
            "shopify_token": shopifyToken,
            "charge_id": chargeId,
            "shop_name": shopName
        });
        var config = {
            method: 'post',
            url: cancelSubscriptionUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setIndicator(false)
                userUpdate()
                getSubscription()
                NotificationManager.success("Subscription canceled successfull")
            })
            .catch(function (error) {
                setIndicator(false)
                NotificationManager.error("Something went wrong")

            });
    }

    const userUpdate = () => {
        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        var data = JSON.stringify({
            query: `mutation{
      userupdate(
        input:{
          username:"${loginInfo.username}",
          shopifyApiInfo:{
            is_subscribed:${0}
          }
        }
      )
    }`,
            variables: {}
        });

        var config = {
            method: 'post',
            url: base_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                getSubscription()
                localStorage.setItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED, 0)
            })
            .catch(function (error) {
                alert("Network Error")
                console.log(error);
            });
    }

    const buySubscription = (packageParam, replace) => {
        localStorage.setItem(DEVELOPMENT_CONFIG.PACKAGE, packageParam)
        let accessToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)
        setIndicator(true)
        if (replace === true) {
            var data = JSON.stringify({
                "shopify_token": accessToken,
                "user_id": loginDetail.id,
                "package_details": {
                    "name": packageParam,
                    "returnUrl": renewRedirectAPI,
                    "replacementBehaviour": "APPLY_IMMEDIATELY",
                    "trialDays": 0,
                    "lineItems": [
                        {
                            "plan": {
                                "appRecurringPricingDetails": {
                                    "price": {
                                        "amount": packageParam === "Basic Monthly" ? process.env.REACT_APP_MONTHLY_PRICE : process.env.REACT_APP_YEARLY_PRICE,
                                        "currencyCode": "USD"
                                    },
                                    "interval": packageParam === "Basic Monthly" ? "EVERY_30_DAYS" : "ANNUAL"
                                }
                            }
                        }
                    ]
                },
                "shop_name": shop
            });
        } else {
            var data = JSON.stringify({
                "shopify_token": accessToken,
                "user_id": loginDetail.id,
                "package_details": {
                    "name": packageParam,
                    "returnUrl": renewRedirectAPI,
                    "trialDays": 0,
                    "lineItems": [
                        {
                            "plan": {
                                "appRecurringPricingDetails": {
                                    "price": {
                                        "amount": packageParam === "Basic Monthly" ? process.env.REACT_APP_MONTHLY_PRICE : process.env.REACT_APP_YEARLY_PRICE,
                                        "currencyCode": "USD"
                                    },
                                    "interval": packageParam === "Basic Monthly" ? "EVERY_30_DAYS" : "ANNUAL"
                                }
                            }
                        }
                    ]
                },
                "shop_name": shop
            });
        }

        var config = {
            method: 'post',
            url: buySubscriptionUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let confirmationUrl = response.data.body.data.appSubscriptionCreate.confirmationUrl
                console.log("confirmationUrl", confirmationUrl)
                window.location.replace(confirmationUrl)
                setIndicator(false)
            })
            .catch(function (error) {
                NotificationManager.error(error.message)
                setIndicator(false)
            });
    }

    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFields({
            ...fields,
            [name]: value
        })
    }


    const subscription_box_css =  (process.env.REACT_APP_YEARLY_SUBSCRIPTION_ACTIVE === 'true' && process.env.REACT_APP_MONTHLY_SUBSCRIPTION_ACTIVE === 'true')?  'col-md-6' : 'col-md-12'



    return (
        <div className="py-2 px-3 my-sm-3">

            {
                indicator ? <Loader/> :
                    <>
                        <div className="wrapper">
                            <div className="header">
                                <h3 className="text-white text-center font-weight-normal headtitle-3">
                                    My Subscription
                                </h3>

                            </div>
                            <div className="content" style={{
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                padding: "0px 20px 20px 20px",
                            }}>
                                <p className="text-red text-center mb-1 pb-1 f-18">
                                    {isSubscribed !== 0 ? subscription?.subscriptionDetails?.message : "You don't have any active Plan"}
                                </p>

                                <div className="row justify-content-center  row-eq-height ">
                                    {process.env.REACT_APP_MONTHLY_SUBSCRIPTION_ACTIVE !== 'true'  ? null :
                                        <div className={`${subscription_box_css} subscript-width mb-md-0 mb-3`}>
                                        <div className="subscription-box">
                                            {
                                                isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Monthly" && subscription?.subscriptionDetails?.subStatus === "active" ?
                                                    <p className="active-badge">Active</p> : null}

                                            <div className="subscription-info">
                                                <h5 className="text-uppercase link">Basic Monthly</h5>
                                                <h2>${process.env.REACT_APP_MONTHLY_PRICE}/month</h2>
                                                <p className="text-white-muted mb-0">Billed at ${process.env.REACT_APP_MONTHLY_PRICE} per month</p>
                                            </div>
                                            <div className="col-12 px-0">
                                                <ul className="check-list list-unstyled mb-4 ">
                                                    <li>Web app access</li>
                                                    <li>Use prerecorded videos</li>
                                                    <li>Connect product collections</li>
                                                    <li>Optimise engagement</li>
                                                    <li>Share & Embed improved videos</li>
                                                </ul>
                                                <div
                                                    className="active-timing d-flex flex-wrap justify-content-between mb-3">
                                                    {
                                                        isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Monthly" &&
                                                        <>
                                                            <div>
                                                                <p className="text-red mb-0">{isSubscribed !== 0 && subscription.subscriptionDetails.type === "trial" ? "Trial Start Date" : "Subscription Start Date"}</p>
                                                                <span className="text-white">
                                  {isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Monthly" && subscription.subscriptionDetails.start_date}
                                </span>
                                                            </div>


                                                            <div>
                                                                <p className="text-red mb-0">{isSubscribed !== 0 && subscription.subscriptionDetails.type === "trial" ? "Trial End Date" : "Subscription End Date"}</p>
                                                                <span className="text-white">
                                  {isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Monthly" && subscription.subscriptionDetails.last_date}
                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className="text-center">
                                                    {
                                                        isSubscribed !== 0 ?
                                                            <>
                                                                {
                                                                    subscription.recurring_application_charge.name === "Basic Monthly" && subscription.recurring_application_charge.status === "active" ?
                                                                        <button onClick={cancelSubscription}
                                                                                type="button"
                                                                                className="btn btn-new-red mt-3 ">Cancel
                                                                            Subscription</button> :
                                                                        <button
                                                                            onClick={() => buySubscription("Basic Monthly", true)}
                                                                            type="button"
                                                                            className="btn btn-new-red mt-3 ">Renew
                                                                            Subscription</button>
                                                                }
                                                            </> :
                                                            <button
                                                                onClick={() => buySubscription("Basic Monthly", false)}
                                                                type="button" className="btn btn-new-red mt-3 ">Buy
                                                                Subscription</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {process.env.REACT_APP_YEARLY_SUBSCRIPTION_ACTIVE !== 'true'  ? null :
                                        <div className={`${subscription_box_css} subscript-width`}>
                                        <div className="subscription-box">
                                            {
                                                isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Yearly" && subscription?.subscriptionDetails?.subStatus === "active" ?
                                                    <p className="active-badge">Active</p> : null}
                                            <div className="subscription-info">
                                                <h5 className="text-uppercase link">Basic Yearly</h5>
                                                <h2>${process.env.REACT_APP_YEARLY_PRICE}/year</h2>
                                                <p className="text-white-muted mb-0">Billed at ${process.env.REACT_APP_YEARLY_PRICE} once per year</p>
                                            </div>
                                            <div className="col-12 px-0">
                                                <ul className="check-list list-unstyled mb-4 ">
                                                    <li>Web app access</li>
                                                    <li>Use prerecorded videos</li>
                                                    <li>Connect product collections</li>
                                                    <li>Optimise engagement</li>
                                                    <li>Share & Embed improved videos</li>
                                                </ul>

                                                <div
                                                    className="active-timing d-flex flex-wrap justify-content-between mb-3">
                                                    {
                                                        isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Yearly" ?
                                                            <>
                                                                <div>
                                                                    <p className="text-red mb-0">{isSubscribed !== 0 && ((subscription.subscriptionDetails.type === "trial") ? "Trial Start Date" : "Subscription Start Date")}</p>
                                                                    <span className="text-white">
                                    {isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Yearly" && subscription.subscriptionDetails.start_date}
                                  </span>
                                                                </div>
                                                                <div>
                                                                    <p className="text-red mb-0">{isSubscribed !== 0 && ((subscription.subscriptionDetails.type === "trial") ? "Trial End Date" : "Subscription End Date")}</p>
                                                                    <span className="text-white">
                                    {isSubscribed !== 0 && subscription.recurring_application_charge.name === "Basic Yearly" && subscription.subscriptionDetails.last_date}
                                  </span>
                                                                </div>
                                                            </> : null
                                                    }
                                                </div>
                                                <div className="text-center">
                                                    {
                                                        isSubscribed !== 0 ?
                                                            <>
                                                                {
                                                                    subscription.recurring_application_charge.name === "Basic Yearly" && subscription.recurring_application_charge.status === "active" ?
                                                                        <button onClick={cancelSubscription}
                                                                                type="button"
                                                                                className="btn btn-new-red mt-3 ">Cancel
                                                                            Subscription</button> :
                                                                        <button
                                                                            onClick={() => buySubscription("Basic Yearly", true)}
                                                                            type="button"
                                                                            className="btn btn-new-red mt-3 ">Renew
                                                                            Subscription</button>
                                                                }
                                                            </> :
                                                            <button
                                                                onClick={() => buySubscription("Basic Yearly", false)}
                                                                type="button" className="btn btn-new-red mt-3 ">Buy
                                                                Subscription</button>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="footer">
&nbsp;
                            </div>
                        </div>
                    </>
            }
        </div>

    );
};

export default Subscribe;
