import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import APP_ROUTES from "../../helper/app-routes";
import {CopyToClipboard} from "react-copy-to-clipboard";
import crossIcon from "../../assets/images/cross.svg";
import {base_url} from "../config/config";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import DEVELOPMENT_CONFIG from "../../helper/config";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ButtonGroup from "../../components/home/common/ButtonGroup";
import "../../components/home/common/button-group.css";

const PreviewVideo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    localStorage.setItem("videoUrlId", location)
    const [isModal, setIsmodal] = useState(false);
    const [hideGeneratedCode, setHideGeneratedCode] = useState(true);
    const [VideoId, setVideo] = useState()
    const [videoSize, setVideoSize] = useState(0)
    const [finalVideoData, setFinalVideoData] = useState({})
    const [width, setWidth] = useState("")
    const [height, setHeight] = useState("")
    const [aspectRatio, setaspectRatio] = useState("")
    const [locked, setLocked] = useState(0)
    const [orientation, setOrientation] = useState("")

    const [dismiss, setDismiss] = useState(false)
    const ref = useRef()
    const textAreaRef = useRef()
    const thumbanil = localStorage.getItem("thumbnail")
    useEffect(() => {
        setFinalVideoData(localStorage.getItem("finalVideoData"))
        const VideoId = localStorage.getItem("videoUrlId")
        setVideo(VideoId)
        const lockStatus = localStorage.getItem("locked")
        lockStatus? setLocked(parseInt(lockStatus)) : setLocked(0)

    }, [])

    const [widthValue, setWidthValue] = useState("500")
    const [heightValue, setHeightValue] = useState("500")

    const [email, setEmail] = useState();
    const iframeRef= useRef(null)
    const handleOpenModal = (e) => {
        e.preventDefault()
        setIsmodal(true);
    };

    const onCopyText = () => {
        NotificationManager.success("Video URL to Clipboard")

    };
    const onGenerateCode = () => {
        NotificationManager.success("Code Copied to Clipboard")
        setHideGeneratedCode(false)
    };
    const handlePreview = () => {
        navigate(APP_ROUTES.PREVIEW);
    };
    const handlePreviewExternal = () => {
        window.open(`${process.env.REACT_APP_VIDEO_URL}/video/${finalVideoData}`, '_blank', 'noreferrer')
    };


    const handleActionFocus = (e) => {
        var container = e.currentTarget;
        container.querySelector('.actions').classList.add("active");
    };

    const handleActionBlur = (e) => {
        var container = e.currentTarget;
        container.querySelector('.actions').classList.remove("active");
    };


    const handleValidation = () => {
        let formIsValid = true;
        var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!email || email.trim().length === 0 || !emailPattern.test(email)) {
            formIsValid = false;
            NotificationManager.error("Please enter valid Email")
        }
        return formIsValid;
    }

    const sendEmbededCode = (e) => {
        e.preventDefault()


        if (handleValidation()) {
            let token = localStorage.getItem("token")
            let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
            var data = JSON.stringify({
                query: `mutation{
                sendEmbedCode(
                  email:"${email}",
                  videoId:"${finalVideoData}"
                  comapny_name:"${loginInfo?.brandCode}"
                  embedIframe:"<script src='${process.env.REACT_APP_VIDEO_URL}/js/imursif-script.js'></script><iframe data-id='${finalVideoData}' src='${process.env.REACT_APP_VIDEO_URL}/embed/${finalVideoData}' width='${widthValue}'  allowTransparency='true' class='imursif' style='border:none;overflow:hidden' ></iframe>"
            ) 
        }`,
                variables: {},
            });

            var config = {
                method: "post",
                url: base_url,
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    setDismiss(true)
                    ref.current.click()
                    NotificationManager.success("Email sent successfully")
                })
                .catch(function (error) {
                });
        }
    }



    function handleWidthValueInput(e) {
        var width = 0;
        if (e.target.name === "Small") {
            setVideoSize(0)
            width = orientation === 'landscape'? '640' : '360'
            setWidthValue(width)
            setHeightValue(calculateHeight(width))
        } else if (e.target.name === "Medium") {
            setVideoSize(1)
            width = orientation === 'landscape'? '854' : '480'
            setWidthValue(width)
            setHeightValue(calculateHeight(width))
        } else if (e.target.name === "Large") {
            setVideoSize(2)
            width = orientation === 'landscape'? '1280' : '720'
            setWidthValue(width)
            setHeightValue(calculateHeight(width))
        } else {
            setVideoSize(3)
        }

    }

    function calculateHeight(width) {
        return orientation === 'portrait'? (width / aspectRatio).toFixed(0) : (width * aspectRatio).toFixed(0)
    }
    function handleCustomWidthValueInput(e) {
        setWidthValue(e.target.value)
        setHeightValue(calculateHeight(e.target.value))
    }

    function handleCustomHeightValueInput(e) {
        setHeightValue(e.target.value)
        var width = orientation === 'portrait'? (e.target.value * aspectRatio).toFixed(0) : (e.target.value / aspectRatio).toFixed(0)
        setWidthValue(width)

    }

    const updateVideoStateMutation = (videoId, state) => {



        let token = localStorage.getItem("token")
        var data = JSON.stringify({
            query: `mutation{
          updateVideo(
            id:"${videoId}"
            input:{
            locked:${state}
          }
          ){
            id 
            locked
          }
        }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: base_url,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data.data.updateVideo.locked));
                setLocked(response.data.data.updateVideo.locked)
                if (response.data.data.updateVideo.locked === 0) {
                    NotificationManager.success("Video Unlocked")
                } else {
                    NotificationManager.success("Video Locked")
                }
            })
            .catch(function (error) {
                NotificationManager.info("Network Error. Please try again");
            });

    }

    function handleVideoProtection(e) {
        if (e.target.name === "Unlocked" && locked === 1) {
            updateVideoStateMutation(finalVideoData,0)
        }  else if (e.target.name === "Locked" && locked === 0) {
            updateVideoStateMutation(finalVideoData, 1)
        }

    }


        window.addEventListener('message', function(e) {

            if(e.data.id === undefined)
                return
            var message = e.data;
            if (iframeRef?.current) {

                console.log(message)
                var width = parseInt(getComputedStyle(iframeRef.current).width, 10)
                var height = message.orientation === 'portrait'? width / message.aspectRatio : width * message.aspectRatio

                setOrientation(message.orientation);
                setaspectRatio(message.aspectRatio);
                iframeRef.current.height = height

            }

        } , false);

    window.addEventListener('resize', function(e) {


        if (iframeRef?.current) {

            var width = parseInt(getComputedStyle(iframeRef.current).width, 10)
            iframeRef.current.height = orientation === 'portrait'? width / aspectRatio : width * aspectRatio

        }

    } , false);


    useEffect(() => {
        setWidthValue(orientation === 'landscape'? '640' : '360');
        setHeightValue(orientation === 'landscape'? calculateHeight(640) : calculateHeight(360))
    }, [orientation]);

    return (

            <div className="wrapper">
                <div className="header">
                    <h3 className="text-white text-center font-weight-normal headtitle-3">
                        Publish
                    </h3>
                </div>
                <div className="content">
                        <iframe className={"imursif col-sm-6 col-xs-12"}
                            ref={iframeRef}
                            data-id={finalVideoData}
                            width={"100%"}
                            src={`${process.env.REACT_APP_VIDEO_URL}/embed/${finalVideoData}`}
                            allowFullScreen="false"
                            scrolling="no"
                            frameBorder="0"></iframe>
                        <Tabs className={"col-sm-6 col-xs-12"}>
                            <TabList>
                                <Tab>Share</Tab>
                                <Tab>Embed</Tab>
                                <Tab>Settings</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="" style={{background:"black", padding:"15px"}}>
                                    <p className="text-white text-left">Video URL</p>
                                    <div style={{display:"flex"}}>
                                        <input readOnly={true} ref={textAreaRef} className="form-control mb-3 mb-sm-3" style={{marginRight:"10px"}}
                                               value={`${process.env.REACT_APP_VIDEO_URL}/video/${finalVideoData}`}></input>

                                        <CopyToClipboard
                                            text={`${process.env.REACT_APP_VIDEO_URL}/video/${finalVideoData}`}
                                            onCopy={onCopyText}>
                                            <button className="copy-btn btn-red-color p-2 border-0">
                                                Copy
                                            </button>
                                        </CopyToClipboard>

                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div style={{background:"black", padding:"15px"}}>


                                        <label className="text-white">Video Size</label>
                                    <ButtonGroup
                                        buttons={["Small", "Medium", "Large", "Custom"]}
                                        active={videoSize}
                                        onClick={handleWidthValueInput}
                                    />
                                        <div style={{display: videoSize !==3 ? "none" : "block"}}>
                                            <label className="text-white">Custom Size in Pixels</label>


                                            <div style={{display:"flex", alignItems: "center"}}>
                                                <div style={{display:"flex",  alignItems: "center", flexDirection: "column"}}>
                                                    <span className="text-white-small">Width</span>
                                                    <input
                                                       value={widthValue} onChange={handleCustomWidthValueInput}
                                                       className="form-control mb-3 mb-sm-3" type="number"></input>
                                                </div>
                                            <span className="text-white" style={{marginLeft:"10px",marginRight:"10px"}}> X </span>
                                                <div style={{display:"flex", alignItems: "center", flexDirection: "column"}}>
                                                    <span className="text-white-small">Height</span>
                                                    <input onChange={handleCustomHeightValueInput}
                                                           value={heightValue}
                                                        className="form-control mb-3 mb-sm-3" type="number"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <label className="text-white">Embed Code</label>
                                        <textarea readOnly={true} style={{height:"230px"}}
                                               value={`<script src="${process.env.REACT_APP_VIDEO_URL}/js/imursif-script.js"></script><iframe data-id="${finalVideoData}" src="${process.env.REACT_APP_VIDEO_URL}/embed/${finalVideoData}" width="${widthValue}" allowfullscreen="false" class="imursif" scrolling="no" frameborder="0"></iframe>`}
                                               className="form-control mb-3 mb-sm-3"></textarea>

                                    <div style={{display: "flex",
                                        justifyContent: "center",
                                        flexWrap: "wrap",
                                        flexDirection: "row"}}>
                                    <div className={"col-6  px-1"}>
                                    <CopyToClipboard
                                            text={`<script src="${process.env.REACT_APP_VIDEO_URL}/js/imursif-script.js"></script><iframe data-id="${finalVideoData}" src="${process.env.REACT_APP_VIDEO_URL}/embed/${finalVideoData}" width="${widthValue}" allowTransparency="true" class="imursif" style="border:none;overflow:hidden" ></iframe>`}
                                            onCopy={onGenerateCode}>
                                            <button className="generate-code-btn btn-red-color border-0">
                                                Copy
                                            </button>
                                        </CopyToClipboard>
                                    </div>

                                    <div className={"col-6  px-1"}>
                                        <div
                                            onClick={handleOpenModal}>
                                            <a
                                                className="new-email-to-dev btn-outline-light radius-8 btn-white-outline"
                                                alt=""
                                                data-toggle="modal"
                                                data-target="#emailmodal"
                                                role="button"
                                            >
                                                Email
                                            </a>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div style={{background:"black", padding:"15px"}}>
                                    <label className="text-white">Protect Video</label>
                                    <ButtonGroup
                                        buttons={["Unlocked", "Locked"]}
                                        active={locked}
                                        onClick={handleVideoProtection}
                                    />
                                </div>
                            </TabPanel>
                        </Tabs>


                </div>
                <div className="footer">


                    <div
                        className="col-4 px-1"
                        onClick={() => {
                            localStorage.removeItem("videoUrl");
                            localStorage.removeItem("ItemList")
                            navigate(APP_ROUTES.DASHBOARD);

                        }}
                    >
                        <a className="btn btn-red-color radius-8 w-100 py-3">
                            Dashboard
                        </a>
                    </div>
                    <div style={{display: !locked? "block" : "none"}}
                        className="col-4 px-1"
                        onClick={() => {
                            navigate(APP_ROUTES.EDITOR, {state: {id: finalVideoData, name: "editor"}});
                        }}
                    >
                        <a className="btn btn-red-color radius-8 w-100 py-3">
                            Edit
                        </a>
                    </div>
                    <div className="col-4 px-1">
                        <a

                            className="btn  radius-8 w-100 py-3 text-black bg-white"
                            onClick={() => {
                                localStorage.removeItem("videoUrl");
                                localStorage.removeItem("ItemList");
                                navigate(APP_ROUTES.EDITOR, {state: {name: "createVideo"}});
                            }}
                        >
                            New Video
                        </a>
                    </div>


                </div>

                <div
                    className="modal fade theme-modal email-modal" data-backdrop="static"
                    id="emailmodal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="emailmodalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="custom-header">
                                <h4>Send Email</h4>
                                <button
                                    ref={ref}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <img src={crossIcon} alt=""/>
                                </button>
                            </div>
                            <div className="modal-body px-md-5">

                                <div className="mb-4">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                                <button type="button"

                                        className="btn btn-red-color w-100 py-3 radius-8 mb-3"
                                        onClick={sendEmbededCode}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default PreviewVideo;
