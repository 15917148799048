import React from "react";
import imsurif from "../../assets/images/imursif.png";
import playStore from "../../assets/images/play-store.png";
import playStoresmall from "../../assets/images/google-play-small.png";
import appStore from "../../assets/images/app-store.png";
import APP_ROUTES from "../../helper/app-routes";
import {useNavigate} from "react-router-dom";

const Installer = () => {
  const navigate= useNavigate();

  return (
      <div className="wrapper">
        <div className="header">
          <h3 className="text-white text-center font-weight-normal headtitle-3">
            User Guide
          </h3>
        </div>
        <div className="content" style={{
          justifyContent: "flex-start",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignItems: "center",
        }}>
        <div className="d-flex">
          <div className="col-md-10 mx-auto ">
            <div className="subscription-box">
              <p style={{ color: '#5d46d2', fontWeight: '500', fontSize: 20 }}>STEP 1<span className="ml-2" style={{ fontSize: 20, fontWeight: 300,color: '#5d46d2' }}>- Creating Your Video</span></p>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <p className="guide-text">1.</p>
                <p className="guide-text-new" >Click the "Create Video" button.</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <p className="guide-text">2.</p>
                <p className="guide-text-new" >Upload a pre-recorded video using the "Select Video" button.</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <p className="guide-text">3.</p>
                <p className="guide-text-new" >Add Products from a Collection:</p>
              </div>

              <div style={{ flexDirection: 'row', display: 'flex', paddingLeft: '20px' }}>
                <p className="guide-text">a.</p>
                <p className="guide-text-new" >Go to the Product Library and select a collection.</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex', paddingLeft: '20px'  }}>
                <p className="guide-text">b.</p>
                <p className="guide-text-new" >Use filters like All, Active, Draft, and Archived to narrow down product choices.</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex', paddingLeft: '20px'  }}>
                <p className="guide-text">c.</p>
                <p className="guide-text-new" >Choose individual products or use "Select all" to add all products from the collection.</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex', paddingLeft: '20px'  }}>
                <p className="guide-text">d.</p>
                <p className="guide-text-new" >The selected products will be displayed below the video.</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <p className="guide-text">4.</p>
                <p className="guide-text-new" >Rearrange the product order by dragging and dropping (if you need to).</p>
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <p className="guide-text">5.</p>
                <p className="guide-text-new" >Click "Submit" when you are happy with the arrangement.</p>
              </div>
            </div>
            <div className="mb-4">&nbsp;</div>
            <div className="subscription-box">
              <div className="mb-4">
                <p style={{ color: '#5d46d2', fontSize: 20, fontWeight: '500' }}>STEP 2 -<span className=" ml-2" style={{ fontSize: 20, fontWeight: 300, color: '#5d46d2' }}>Publishing Your Video</span></p>


                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <p className="guide-text">1.</p>
                  <p className="guide-text-new" >Share the video by copying its URL to your clipboard and pasting it. (We suggest: Directly onto social media alongside your product videos, use in emails or straight into a browser to view).</p>
                </div>

                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <p className="guide-text">2.</p>
                  <p className="guide-text-new" >To embed the video on an external page, select the desired size (Small, Medium, Large or Custom Size) or use the email button to send the code directly to your developer (optional).</p>
                </div>

                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <p className="guide-text">3.</p>
                  <p className="guide-text-new" >In "Settings", change the video protection state from "Unlocked" to "Locked", to protect your videos from editing or accidental deletion.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        </div>
          <div className="footer">
            &nbsp;
          </div>
        </div>

  );
};

export default Installer;
