
import React, { Component, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import APP_ROUTES from '../../helper/app-routes';
import DEVELOPMENT_CONFIG from '../../helper/config';

const NotFound = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [token, seToken] = useState(localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN))


    useEffect(() => {
            let token = localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN)
            if (token !== null) {
                navigate(APP_ROUTES.DASHBOARD)
            }
    }, [])

    return (
        <>
            {
                token === null &&
                <div className='dt-background'>
                    <button style={{color:'red'}} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base back-dashboard" > 404 Not Found
                    </button>
                </div>

            }
        </>

    );

}
export default NotFound;



