import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import imuisif from "../../assets/images/imursif_with_white_text.png";
import axios from 'axios';
import Loader from '../../components/home/common/Loader';
import DEVELOPMENT_CONFIG from "../../helper/config";
import {NotificationManager} from "react-notifications";
import {base_url, buySubscriptionUrl, chargeRedirectAPI, redirectURL, SCOPES, SHOPIFY_API_KEY} from "../config/config";
import APP_ROUTES from "../../helper/app-routes";
import moment from 'moment';
const Register = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [shopName, setShopName] = useState("")
    let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP);
    const shopify_token = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
    const navigate = useNavigate()
    const [indicator, setIndicator] = useState(false)
    const [registerState, setRegisterState] = useState({
        emailState: null,
        usernameState: null

    })
    const [errors, setErrors] = useState("");
    const affId = searchParams.get('affid')
    const [fields, setFields] = useState({
        shop_name: shop === null ? "" : shop,
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        company_name: "",
        accessToken: '',
        package: '',
    })

    const [affiliateCode, setaffiliateCode] = useState(affId === null ? localStorage.getItem(DEVELOPMENT_CONFIG.AFFILIATE_CODE) : affId)

    const handleOnChange = (e) => {
        let usernameRegex = new RegExp("^(?=[a-zA-Z0-9._]{7,20}$)(?!.*[_.]{2})[^_.].*[^_.]$")
        const name = e.target.name;
        const value = e.target.value;

        setFields({
            ...fields,
            [name]: value
        })

        if (name === "username") {
            if (usernameRegex.test(fields.username)) {
                console.log(usernameRegex.test(fields.username))
                setRegisterState({
                    ...registerState,
                    usernameState: false
                })
            } else {
                console.log(usernameRegex.test(fields.username))
                setRegisterState({
                    ...registerState,
                    usernameState: true
                })
            }
        }
    }

    const checkOAuth = () => {
        if (shopify_token !== null) {
            registration()
        } else {
            localStorage.setItem(DEVELOPMENT_CONFIG.FIELDS, JSON.stringify(fields))
            localStorage.setItem(DEVELOPMENT_CONFIG.SHOP, fields.shop_name)
            localStorage.setItem(DEVELOPMENT_CONFIG.AFFILIATE_CODE, affiliateCode)
            let shopifyURL = "https://" + fields.shop_name + "/admin/oauth/authorize?client_id=" + SHOPIFY_API_KEY + "&scope=" + SCOPES + "&state=" + 'nonce' + "&redirect_uri=" + redirectURL;

            console.log("ShopifyUrl", shopifyURL)

            window.location.replace(shopifyURL)
        }
    }


    const buySubscription = () => {
        var data = JSON.stringify({
            "shopify_token": shopify_token,
            "package_details": {
                "name": fields.package === "monthly" ? "Basic Monthly" : "Basic Yearly",
                "returnUrl": chargeRedirectAPI,
                "test": true,
                "trialDays": 14,
                "lineItems": [
                    {
                        "plan": {
                            "appRecurringPricingDetails": {
                                "price": {
                                    "amount": fields.package === "monthly" ? 9 : 99,
                                    "currencyCode": "USD"
                                },
                                "interval": fields.package === "monthly" ? "EVERY_30_DAYS" : "ANNUAL"
                            }
                        }
                    }
                ]
            },
            "shop_name": fields.shop_name
        });

        var config = {
            method: 'post',
            url: buySubscriptionUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setIndicator(false)
                let confirmationUrl = response.data.body.data.appSubscriptionCreate.confirmationUrl
                console.log(fields, typeof (fields))
                localStorage.setItem(DEVELOPMENT_CONFIG.REGISTRATION_INFO, JSON.stringify(fields))
                window.location.replace(confirmationUrl)
            })
            .catch(function (error) {
                NotificationManager.error(error.message)
                setIndicator(false)

            });
    }

    const handleSubmit = () => {
        localStorage.setItem(DEVELOPMENT_CONFIG.FIELDS, JSON.stringify(fields))
        if (handleValidation()) {
            checkOAuth();
        }
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let usernameRegex = new RegExp("^(?=[a-zA-Z0-9._]{7,20}$)(?!.*[_.]{2})[^_.].*[^_.]$")

        if (!fields.username || fields.username.trim().length === 0) {
            formIsValid = false;
            errors["username"] = "This field can't be blank"
        }
        if (!fields.first_name || fields.first_name.trim().length === 0) {
            formIsValid = false;
            errors["first_name"] = "This field can't be blank"
        } else if (!usernameRegex.test(fields.username)) {
            formIsValid = false;
            errors["username"] = "Please enter valid username"
        }
        if (!fields.email || fields.email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = "This field can't be blank"
        } else if (!emailPattern.test(fields.email)) {
            formIsValid = false;
            errors["email"] = "Please enter valid email"

        }
        if (!fields.last_name || fields.last_name.trim().length === 0) {
            formIsValid = false;
            errors["last_name"] = "This field can't be blank"
        }
        if (!fields.company_name || fields.company_name.trim().length === 0) {
            formIsValid = false;
            errors["company_name"] = "This field can't be blank"
        }
        // if (!fields.package || fields.package.trim().length === 0) {
        //   formIsValid = false;
        //   errors["package"] = "This field can't be blank"
        // }
        setErrors(errors)

        return formIsValid;
    }

    const registration = () => {

        setIndicator(true)


        let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        let today = new Date()
        let trialStartDate = moment(today).format('YYYY-MM-DD')
        let trialEndDate = moment(today).add(process.env.REACT_APP_TRIAL_DAYS, 'days').format('YYYY-MM-DD')

        var data = JSON.stringify({
            query: `mutation {
                  register(
                    input: {
                      username:  "${fields.username}"
                      email:  "${fields.email}"
                      name:  "${fields.first_name}"
                      lastName:  "${fields.last_name}"
                      companyName:  "${fields.company_name}"
                      shopifyApiInfo: {
                        storeSubdomain: "${fields.shop_name}"
                        accessToken:  "${shopifyToken}"
                        chargeId: "${""}"
                        billingOnDate: "${""}"
                        is_shopify_login:${1},
                        is_subscribed:${0},
                        trial_start:"${trialStartDate}"
                        trial_end:"${trialEndDate}",
                        subscription_start:"${""}"
                        subscription_end:"${""}",
                        package:"${""}"
                        affiliateCode:"${affiliateCode}",

                      }
                    }
                  )
                }`,
            variables: {}
        });

        var config = {
            method: 'post',
            url: base_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                if (response.data.data.register === true) {
                    NotificationManager.success("Registration Successfull")
                    navigate(APP_ROUTES.LOGIN)
                    // buySubscription()

                } else {
                    setIndicator(false)
                    NotificationManager.error("User Already Exists , Please use a unique Username and Email")
                }
            })
            .catch(function (error) {
                setIndicator(false)
            });

    }

    useEffect(() => {

        let fields = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.FIELDS))
        if (fields !== null) {
            setFields(fields)
        }

    }, [])

    return (
        <div className="registeration-page">
            <div className="wrapper">
                <div className="content register-page">

                    <div className="container text-center">
                        <h2>Registration</h2>
                        <p className="p-head text-center">Please register and link your shopify store</p>
                        <div className="center-block ">
                            <div className="row align-item-center h-100 justify-content-center w-100">
                                <div className="col-md-4">
                                    <div className="d-flex align-items-center justify-content-center h-100">
                                        <div className="mb-md-5 pb-md-5">
                                            <img src={imuisif} className="logo"/>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-block">
                                    {indicator && <Loader/>}

                                    <div className="">

                                        {/* {
                    shopify_token === null ?

                      <input
                        type="text"
                        required
                        name="shop_name"
                        disabled={shopify_token !== null ? true : false}
                        value={fields.shop_name}
                        placeholder="Store Url for Shopify"
                        className="input-control"
                        onChange={handleOnChange}
                      /> : null

                  } */}


                                        <input
                                            type="text"
                                            required
                                            name="username"
                                            placeholder="Username"
                                            className={errors.username ? "input-control invalid-input" : "input-control"}
                                            onChange={handleOnChange}
                                            value={fields.username}
                                        />

                                        {
                                            registerState.usernameState === true &&
                                            <p style={{color: "red"}} className="error-p">Usernames only contains
                                                alphanumeric characters, underscore and dot and must be 8-20 characters
                                                long.</p>
                                        }

                                        <input
                                            type="email"
                                            required
                                            name="email"
                                            placeholder="Email" pdate
                                            className={errors.email ? "input-control invalid-input" : "input-control"}
                                            onChange={handleOnChange}
                                            value={fields.email}
                                        />
                                        <input
                                            type="text"
                                            required
                                            name="first_name"
                                            placeholder="First Name"
                                            className={errors.first_name ? "input-control invalid-input" : "input-control"}
                                            onChange={handleOnChange}
                                            value={fields.first_name}

                                        />
                                        {/* <p className="error-p"> {errors.first_name}</p> */}

                                        <input
                                            type="text"
                                            required
                                            name="last_name"
                                            placeholder="Last Name"
                                            className={errors.last_name ? "input-control invalid-input" : "input-control"}
                                            onChange={handleOnChange}
                                            value={fields.last_name}


                                        />
                                        {/* <p className="error-p"> {errors.last_name}</p> */}

                                        <input
                                            type="text"
                                            required
                                            name="company_name"
                                            placeholder="Company Name"
                                            className={errors.company_name ? "input-control invalid-input" : "input-control"}
                                            onChange={handleOnChange}
                                            value={fields.company_name}
                                        />
                                        {/* <p className="error-p"> {errors.company_name}</p> */}

                                        {/* <select
                    required
                    name="package"
                    placeholder="package"
                    className={errors.package ? "input-control invalid-input" : "input-control"}
                    onChange={handleOnChange}
                    value={fields.package}
                  >


                    <option value="">Select Package</option>
                    <option value="monthly">Basic Monthly ($9) - 14 days trial</option>
                    <option value="yearly">Basic Yearly ($99) - 14 days trial</option>
                  </select> */}
                                        {/* <p className="error-p"> {errors.package}</p> */}

                                        <input
                                            type="hidden"
                                            required
                                            name="accessToken"
                                            value="<%= accesstoken %>"
                                            onChange={handleOnChange}

                                        />

                                        <input type="hidden" id="emailValidataionValue" value="0"
                                               onChange={handleOnChange}/>

                                        <button
                                            className="btn-save border-0 mt-4"
                                            onClick={handleSubmit}
                                        >
                                            Signup
                                        </button>
                                        <p style={{textAlign: 'center', marginTop: '20px'}}>
                                            {" "}
                                            Already have an account?
                                            <Link to="/login" className="link" style={{marginLeft: '3px'}}>Login</Link>
                                        </p>
                                        <h3 className="text-center mt-10">v {process.env.REACT_APP_VERSION}</h3>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{height: '80px'}} className="footer">

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
