import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import DEVELOPMENT_CONFIG from "../../helper/config";
import AppRoutes from "../../helper/app-routes";

const Affiliate = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = getCodeFromSearchParams(searchParams);
    useEffect(() => {
        if (!code) {
           navigate(AppRoutes.LOGIN);
        } else {
            localStorage.setItem(DEVELOPMENT_CONFIG.AFFILIATE_CODE, code)
            window.location.replace('https://apps.shopify.com/imursif');
        }
    }, []);
}

// Helper function to get the affiliate code from search parameters case-insensitively
const getCodeFromSearchParams = (searchParams) => {
    const keys = [...searchParams.keys()];
    const lowercaseKeys = keys.map(key => key.toLowerCase());

    const affidKeyIndex = lowercaseKeys.indexOf('affid');
    if (affidKeyIndex !== -1) {
        const affidKey = keys[affidKeyIndex];
        return searchParams.get(affidKey);
    }

    return null;
}



export default Affiliate;

