import React, { useState, useEffect } from "react";
import './App.css';
import '../src/custom-css/custom-style.css';
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import AppRoutes from "./helper/app-routes";
import LogIn from "./app_modules/login/login";
import DEVELOPMENT_CONFIG from "./helper/config";
import { useNavigate } from "react-router-dom";
import Register from "./app_modules/register/register";
import Dashboard from "./app_modules/dashboard/Dashboard";
import Editor from "./app_modules/editor/Editor";
import ProductList from "./app_modules/dashboard/Product";
import Layout from "./components/home/common/Layout";
import APP_ROUTES from "./helper/app-routes";
import NotFound from "./app_modules/not-found/not-found";
import Protected from "./container/protected";
import Installer from "./app_modules/installer/Installer";
import Subscribe from "./app_modules/subscribe/Subscribe";
import Profile from "./app_modules/profile/Profile";
import RenderedVideo from './app_modules/dashboard/RenderedVideo'
import Preview from "./app_modules/dashboard/Preview";
import { redirectURL, SCOPES, SHOPIFY_API_KEY } from "./app_modules/config/config";
import ShopifyApiCallBack from "./app_modules/api_callback";
import Thankyou from "./app_modules/register/thankyou";
import ReturnUrl from "./components/home/returnURl";
import ReorderableListExample from "./components/home/common/DraggableList";
import LoginCallback from "./app_modules/api_callback/login_callback";
import WishlistItems from "./app_modules/dashboard/cart";
import Affiliate from "./app_modules/affiliate";
const PrivateRoutes = () => {
  return (
    <div>
      <Layout>
        <Routes>

          <Route path={APP_ROUTES.LOGINCALLBACK} element={<LoginCallback />} />

          <Route
            path={AppRoutes.DASHBOARD}
            element={<Protected Component={Dashboard} />}
          />
          <Route
            path={AppRoutes.EDITOR}
            element={<Protected Component={Editor} />}
          />
          <Route
            path={AppRoutes.PRODUCT_LIST}
            element={<Protected Component={ProductList} />}
          />
          <Route
            path={AppRoutes.INSTALLER}
            element={<Protected Component={Installer} />}
          />
          <Route
            path={AppRoutes.SUBSCRIBER}
            element={<Protected Component={Subscribe} />}
          />
          <Route
            path={AppRoutes.PROFILE}
            element={<Protected Component={Profile} />}
          />
          <Route
            path={AppRoutes.RENDERED}
            element={<Protected Component={RenderedVideo} />}
          />
          <Route
            path={AppRoutes.PREVIEW}
            element={<Protected Component={Preview} />}
          />

          <Route
            path={AppRoutes.CART}
            element={<Protected Component={WishlistItems} />}
          />

          <Route
            path={"drag"}
            element={<Protected Component={ReorderableListExample} />}
          />



          <Route path="*" element={<Protected Component={NotFound} />} />

        </Routes>
      </Layout>
    </div>
  );
};


function App() {
  const [shopName, setShopName] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const location = useLocation();

  let token = localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN);


  useEffect(() => {
    if (location.pathname === "/") {
      let shopName = searchParams.get("shop")
      if (shopName !== null) {
        console.log("SHOPPNAME", shopName)
        localStorage.setItem(DEVELOPMENT_CONFIG.SHOP, shopName);
        localStorage.setItem(DEVELOPMENT_CONFIG.IS_SHOPIFY_LOGIN, 1);
        let shopifyURL = "https://" + shopName + "/admin/oauth/authorize?client_id=" + SHOPIFY_API_KEY + "&scope=" + SCOPES + "&state=" + 'nonce' + "&redirect_uri=" + redirectURL;
        window.location.replace(shopifyURL)
      } else {

        let token = localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN)
        let accessToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)

        if (token !== null && accessToken !== null) {
          navigate(AppRoutes.DASHBOARD)
        } else {
          navigate(AppRoutes.LOGIN)
        }
      }
    }

  }, [])



  return (
    <div>
      {token ? (
        <PrivateRoutes />
      ) : (
        <Routes>
          <Route path={APP_ROUTES.LOGIN} element={<LogIn />} />
          <Route path={APP_ROUTES.REGISTER} element={<Register />} />
          <Route path={APP_ROUTES.CALLBACK} element={<ShopifyApiCallBack />} />
          <Route path={APP_ROUTES.THANKYOU} element={<Thankyou />} />
          <Route path={APP_ROUTES.RETURN_URL} element={<ReturnUrl />} />
          <Route path={APP_ROUTES.AFFILIATE} element={<Affiliate />} />
        </Routes>
      )}

    </div>
  );
}

export default App;
