import React from "react";
function ErrorFallback(props) {
    return (
        <div role="alert">
            <h2 style={{color:'red'}}>Something went wrong {JSON.stringify(props)} </h2>

        </div>
    )
}

export default ErrorFallback