import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import imursifLogo from '../../assets/images/imursif.png';
import DEVELOPMENT_CONFIG from "../../helper/config";

import axios from "axios";
import { useNavigate } from 'react-router-dom';
import APP_ROUTES from "../../helper/app-routes";
import { base_url } from "../config/config";


const Thankyou = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN);
    let regInfoDetail = localStorage.getItem(DEVELOPMENT_CONFIG.REGISTRATION_INFO);
    let regInfo = JSON.parse(regInfoDetail)


    useEffect(() => {

        setTimeout(()=>{
          navigate(APP_ROUTES.LOGIN)

        },[2000])

        // let chargeId = searchParams.get("charge_id")
        // localStorage.setItem(DEVELOPMENT_CONFIG.CHARGE_ID, chargeId)
        // getChargeDetail(chargeId)

    })



    const registration = (billingOn,chargeId) => {
        var data = JSON.stringify({
          query: `mutation {
                      register(
                        input: {
                          username:  "${regInfo.username}"
                          email:  "${regInfo.email}"
                          name:  "${regInfo.first_name}"
                          lastName:  "${regInfo.last_name}"
                          companyName:  "${regInfo.company_name}"
                          shopifyApiInfo: {
                            storeSubdomain: "${regInfo.shop_name}"
                            accessToken:  "${shopifyToken}"
                            chargeId: "${chargeId}"
                            billingOnDate: "${billingOn}"
                          }
                        }
                      )
                    }`,
          variables: {}
        });
        
        var config = {
          method: 'post',
          url: base_url,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          navigate(APP_ROUTES.LOGIN)

        })
        .catch(function (error) {
        });
        
    }



    return (
        <div className="thankyou-app">
            <div className="container">
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className="col-md-6 text-center">
                      <img src={imursifLogo} className="logo"/>
                        <h2 className="red-color thankyou-stroke mb-3">Thankyou </h2>
                        <h4 className="text-white"> For registering with Us.</h4>
                        <p className="text-white"> We have sent an email to your account including your login details.</p>
                    </div>             
                </div>
            </div>
        </div>

    );
};

export default Thankyou;
