const APP_ROUTES = {

    REGISTER: "/register",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    EDITOR: "/editor",
    PRODUCT_LIST: "/product-list",
    INSTALLER: "/installer",
    SUBSCRIBER: '/subscriber',
    PROFILE: "/profile",
    RENDERED: "/previewVideo",
    PREVIEW: "/preview",
    CALLBACK: "/shopify-api/callback",
    THANKYOU: "/thankyou",
    RETURN_URL: "/returnUrl",
    LOGINCALLBACK: "/login-api/callback",
    CART: "/wishlist-items",
    AFFILIATE: "/affiliate",


};

export default APP_ROUTES;
