import React, { useEffect, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/home/common/Loader";
import APP_ROUTES from "../../helper/app-routes";
import DEVELOPMENT_CONFIG from "../../helper/config";
import {  base_url, getAccessTokenUrl, getChargeDetails, renewRedirectAPI, SHOPIFY_API_KEY, SHOPIFY_API_SECRET } from "../config/config";

const LoginCallback = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    let code = searchParams.get("code");
    let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)
    const [indicator, setIndicator] = useState(false)
    const getAccessToken = (code) => {
        setIndicator(true)
        var data = JSON.stringify({
            client_id: SHOPIFY_API_KEY,
            client_secret: SHOPIFY_API_SECRET,
            code: code,
            shop_name: shop
        });

        var config = {
            method: "post",
            url: getAccessTokenUrl,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {

                setIndicator(false)
                localStorage.setItem(
                    DEVELOPMENT_CONFIG.ACCESS_TOKEN,
                    response.data.body.access_token
                );

                updateAccessToken(response.data.body.access_token)


            })
            .catch(function (error) {
                setIndicator(false)
                NotificationManager.error(error.message);
            });
    };


    const updateAccessToken = (accessToken) => {
        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        var data = JSON.stringify({
            query: `mutation{
                        userupdate(
                            input:{
                            username:"${loginInfo.username}",
                            shopifyApiInfo:{
                                is_shopify_login:${1}
                                accessToken:"${accessToken}"
                            }
                            }
                        )
                        }`,
            variables: {}
        });

        var config = {
            method: 'post',
            url: base_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                localStorage.setItem(DEVELOPMENT_CONFIG.IS_SHOPIFY_LOGIN,1)
                navigate(APP_ROUTES.DASHBOARD)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    useEffect(() => {
        getAccessToken(code)
    }, [])

    return (
        <>
            {indicator && <Loader />}

        </>
    )
}

export default LoginCallback