import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getChargeDetails } from '../../../app_modules/config/config';
import APP_ROUTES from '../../../helper/app-routes';
import DEVELOPMENT_CONFIG from '../../../helper/config';
import imursif from "../../../assets/images/imursif_with_white_text.png";

const Header = () => {

  return (
    <div className="header-fix">
      <i className="fa-solid fa-bars text-white d-lg-none d-block open-menu p-3" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"></i>
        <img src={imursif}  className="logoimg" alt="imursif" />
    </div>
  )
}

export default Header;