import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getChargeDetails} from '../../../app_modules/config/config';
import APP_ROUTES from '../../../helper/app-routes';
import DEVELOPMENT_CONFIG from '../../../helper/config';

const Trial = () => {
    const [freeTrialAvailability, setFreeTrialAvailability] = useState(true)
    const [days, setDays] = useState(0)

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(APP_ROUTES.SUBSCRIBER)
    }


    function getSubscription() {
        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        let accessToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        let chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID)

        var data = JSON.stringify({
            "shopify_token": accessToken,
            "chargeId": chargeId,
            "shop_name": loginInfo.shopifyApiInfo.storeSubdomain,
            "user_id": loginInfo.id
        });

        var config = {
            method: 'post',
            url: getChargeDetails,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                localStorage.setItem(DEVELOPMENT_CONFIG.DAYS, response.data.body.days)
                setDays(response.data.body.days)
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {

        let chargeId = searchParams.get("charge_id")
        if (chargeId !== null) {
            setFreeTrialAvailability(false)
        }
        getSubscription()
        const isSubscribed = localStorage.getItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)
        if (isSubscribed === 1) {
            setFreeTrialAvailability(false)
        }
    }, [])

    return (
        <>
            {freeTrialAvailability ?
                <div className="trial-fix">
                    <p className='mb-0 text-center headeralert' style={{color: '#fff'}}>
                        Free Trial {days <= 0 ? 0 : days - 1} days left - any videos you create will expire at the end of
                        your trial. Just <a href=''
                                         onClick={handleClick} className='link'>Click here</a>  to subscribe to our service and
                        keep them live.
                    </p>
                </div>
                : null
            }
        </>
    )
}

export default Trial;