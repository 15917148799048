import React, {useEffect, useRef, useState} from "react";

import {useNavigate} from "react-router-dom";
import APP_ROUTES from "../../helper/app-routes";

const Preview = () => {
    const navigate = useNavigate();
    const [finalVideoData, setFinalVideoData] = useState({});
    const [returnPage, setReturnPage] = useState(APP_ROUTES.RENDERED);

    useEffect(() => {
        window.addEventListener('message', function (e) {

            if (e.data.id === undefined) {

                return
            }

            var message = e.data;

            var selector = ".imursif[data-id='" + message.id + "']"
            var iframes = document.querySelectorAll(selector);
            var mainContentBody = document.querySelector('.main-content-body');
            var width = mainContentBody.offsetWidth;
            var height = mainContentBody.offsetHeight - 20;
            iframes.forEach((iframe) => {
                const orientation = message.width > message.height ? 'landscape' : 'portrait';

                if (orientation === 'landscape') {
                    iframe.style.height = `${width * (message.height / message.width)}px`;
                    iframe.style.width = `${width}px`;
                } else {
                    iframe.style.height = `${height}px`;
                    iframe.style.width = `${height * (message.width / (message.height + 155))}px`;
                }
            })
        });

        setFinalVideoData(localStorage.getItem("finalVideoData"))

        setReturnPage(localStorage.getItem("returnPage") || APP_ROUTES.RENDERED)
        localStorage.setItem("returnPage", '')
    }, []);
    return (
        <div className="main-content-body">
            <div className="body-wrapper">
                <div className="center-wrapper">
                    <h3 className="text-white text-center font-weight-normal headtitle-3">
                        Preview
                    </h3>
                    <div className="preview-content">
                        <iframe
                            width={"100%"}
                            data-id={finalVideoData}
                            className="imursif"
                            src={`${process.env.REACT_APP_VIDEO_URL}/embed/${finalVideoData}?`}
                            allowFullScreen="false"
                            scrolling="no"
                            frameBorder="0"></iframe>
                        <div
                            className="col-6 px-1"
                            onClick={() => {
                                navigate(returnPage);

                            }}
                        >
                            <button
                                type="submit"
                                className="btn btn-lg btn-red-color py-3 px-2 btn-block max-400 mx-auto radius-8 "
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preview;
