import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { base_url } from "../../app_modules/config/config";
import APP_ROUTES from "../../helper/app-routes";
import DEVELOPMENT_CONFIG from "../../helper/config";

const ReturnUrl = () =>{
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    let regInfoDetail = localStorage.getItem(DEVELOPMENT_CONFIG.REGISTRATION_INFO);
    let regInfo = JSON.parse(regInfoDetail)

    let shopName = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP);
    let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN);

    const getChargeDetail = (chargeId) => {
        var data = JSON.stringify({
            "shopify_token": shopifyToken,
            "charge_id": chargeId,  
            "shop_name":shopName
        });

        var config = {
            method: 'post',
            url: "https://imursif-node.herokuapp.com/get-charge-details",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let billingOn = response.data.body.recurring_application_charge.billing_on
                updateChargeId(billingOn,chargeId)

            })
            .catch(function (error) {
                
            });
    }

    const updateChargeId = (billingOn, chargeId) => {
    let fields = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.FIELDS))
    var data = JSON.stringify({
      query: `mutation{
      userupdate(
        input:{
          username:"${fields.username}",
          shopifyApiInfo:{
            storeSubdomain:"${shopName}"
            chargeId:"${chargeId}",
            billingOnDate:"${billingOn}"
            accessToken:"${shopifyToken}"
          }
        }
      )
    }`,
      variables: {}
    });

    var config = {
      method: 'post',
      url: base_url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        navigate(APP_ROUTES.THANKYOU)
      })
      .catch(function (error) {
        alert("Network Error")
        console.log(error);
      });

    }

    useEffect(()=>{
        let chargeId = searchParams.get("charge_id")
        localStorage.setItem(DEVELOPMENT_CONFIG.CHARGE_ID, chargeId)
        getChargeDetail(chargeId)
    },[])
}

export default ReturnUrl