import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/home/common/Loader";
import DEVELOPMENT_CONFIG from "../../helper/config";
import { updateUserSubscription } from "../config/config";
import CreateVideo from "./Createvideo";


const Dashboard = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate =useNavigate()

  const [indicator,setIndicator] = useState(false)

  const updateChargeID = (chargeId,id) =>{
    setIndicator(true)
    var data = JSON.stringify({
      "charge_id": chargeId,
      "user_id": id
    });
    var config = {
      method: 'post',
      url:updateUserSubscription,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      localStorage.setItem(DEVELOPMENT_CONFIG.CHARGE_ID, chargeId)
      localStorage.setItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED, 1)
      setIndicator(false)
    })
    .catch(function (error) {
      setIndicator(false)
      console.log(error);
    });
  }

  useEffect(() => {
    let chargeId = searchParams.get("charge_id")
    let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
    if (chargeId !== null ) {
      localStorage.setItem(DEVELOPMENT_CONFIG.CHARGE_ID, chargeId)
      updateChargeID(chargeId, loginInfo.id)
     
    }
  }, [])

  return (

    <>
    {indicator ? <Loader/>:<CreateVideo/>}
    </>
   
  );
}; 

export default Dashboard;
