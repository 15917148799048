import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteSvg from "../../assets/images/delete.svg";
import { NotificationManager } from "react-notifications";
import DEVELOPMENT_CONFIG from "../../helper/config";

const WishlistItems = () => {
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalItems, setTotalItems] = useState(0)

    const handleClick = () => {
        let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)
        if (cartItems.length === 0) {
            NotificationManager.error("No items in cart")
        } else {
            let link = "http://" + shop + "/cart/"
            if (cartItems !== null) {
                for (const [key, value] of Object.entries(cartItems)) {
                    if (value  !== undefined && value  !== null) {
                        link = link + value.variant.id + ":" + value.quantity + ","
                    }
                }
            }
            link = link.substring(0, link.length - 1)
            link = link + "?storefront=true"
            window.open(link, "_blank")
        }
    };

    const removeItemsFromCart = (item) => {
        let array = [...cartItems];
        const index = array.indexOf(item);
        if (index > -1) {
            // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }

        setCartItems(array);
        localStorage.setItem("cartItems", JSON.stringify(array))

        // dispatch(setCartItemsPreview(array))

        checkCartItems();
    };

    const checkCartItems = () => {
        let cartItemsSelected = JSON.parse(localStorage.getItem("cartItems"))

        if (cartItemsSelected !== null) {
            let total = 0
            cartItemsSelected.forEach(element => {
                total = total + element.quantity
            });
            setTotalItems(total)
            updateTotal(cartItemsSelected)
            setCartItems(cartItemsSelected);
        } else {
            setCartItems([]);
        }

    };


    const updateTotal = (cartItems) => {
        let price = 0.0;
        cartItems.forEach((element) => {
            price += parseFloat(element.variant.price * element.quantity);
        });
        setTotalPrice(price.toFixed(2));
    }
    const updateQuantity = (cartItems) => {
        let total = 0;
        cartItems.forEach((element) => {
            total = element.quantity + total
        });
        setTotalItems(total)
        localStorage.setItem("cartItems", JSON.stringify(cartItems))
    }


    useEffect(() => {
        checkCartItems()
    }, [])

    return (
        <div className="center-wrapper">
            <div className="heading-grid mb-lg-4 mb-3 mt-3 px-3">
                <a className="text-white d-inline-block mr-2">
                    <i
                        onClick={() => navigate(-1)}
                        role={"button"}
                        className="fa fa-chevron-left"
                    ></i>
                </a>
                <h3 className="text-white font-weight-normal text-md-center text-left headtitle-3 mb-0">
                    Wishlist Items
                </h3>
            </div>
            <div className="px-3 ">
                <div className="cart-inner-scroll thin-scroll">
                    {cartItems.length > 0 &&
                        cartItems.map((item) => {
                            function addQuantity() {
                                item.quantity += 1
                                updateQuantity(JSON.parse(JSON.stringify(cartItems)))
                                updateTotal(JSON.parse(JSON.stringify(cartItems)))
                            }

                            function removeQuantity() {
                                if (item.quantity > 1) {
                                    item.quantity -= 1
                                    updateQuantity(JSON.parse(JSON.stringify(cartItems)))
                                    updateTotal(cartItems)
                                }

                            }


                            return (
                                <div className="cartitem">
                                    <img style={{ objectFit: 'contain' }} src={item.productImage} />
                                    <div style={{ flexDirection: 'column' }}>
                                        <p className="mb-2">{item.productTitle}</p>
                                        <div className="d-flex justify-content-start text-center">

                                            <span className="label-size">{item.variant.title}</span>
                                            <button onClick={() => removeQuantity()} className="cart-item-buttons">-</button>
                                            <span className="px-2">{item.quantity}</span>
                                            <button onClick={() => addQuantity()} className="cart-item-buttons">+</button>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => removeItemsFromCart(item)}
                                        className="cart-delete"
                                    >
                                        <img src={DeleteSvg} />
                                    </button>
                                </div>
                            );
                        })}
                </div>
                <div className="cartinfo-table">
                    <div className="d-flex align-items-end justify-content-between text-white py-2 mb-2 bggrey px-2">
                        <p className="font-weight-bold mb-0">QUANTITY</p>
                        <p className="font-weight-bold mb-0">TOTAL</p>
                    </div>
                    <div className="d-flex align-items-end justify-content-between text-white px-2 ">
                        <p className="">{totalItems} Items</p>
                        <p className=""> £ {totalPrice}</p>
                    </div>
                </div>
                <button
                    onClick={handleClick}
                    className="btn btn-lg btn-red-color py-3 px-2 btn-block max-400 mx-auto radius-8"
                >
                    Continue
                </button>
            </div>
        </div>
    );

}
export default WishlistItems
