import React from "react";
import * as classNames from 'classnames'
import { DragDropContext,Draggable,Droppable } from "react-beautiful-dnd";

const listItems = Array(10).fill(0).map((_, i) => i);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ReorderableListExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: listItems,
    };
  
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable  droppableId="droppable" >
          {(provided, snapshot) => (
            <div
            className={classNames('list', snapshot.isDraggingOver && 'draggingOver')}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item} draggableId={`${item}-id`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={classNames('item', snapshot.isDragging && 'dragging')}
                      style={provided.draggableProps.style}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      List item {item + 1}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default ReorderableListExample