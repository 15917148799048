import React, {useEffect} from "react";

import Header from "./Header";
import Sidemenu from "./Sidemenu";
import Dashboard from "../../../app_modules/dashboard/Dashboard";
import Trial from "./Trial";
import {useLocation} from "react-router-dom";



const Layout = ({ children }) => {
    const location = useLocation()
    useEffect(() => {

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            const isChrome = /CriOS/i.test(navigator.userAgent);
            const isFirefox = /FxiOS/i.test(navigator.userAgent);
            const isOpera = /OPiOS/i.test(navigator.userAgent);
            const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome && !isFirefox && !isOpera;
            const viewportHeight = window.innerHeight;
            if (document.getElementsByClassName("wrapper").length > 0){
                    document.getElementsByClassName("wrapper")[0].style.height = `calc(${viewportHeight}px - 100px)`;
            }
        }


    }, [location]);

    return (
        <section className="main-body">
            <Sidemenu />

            <div className="main-content">
                <Header />
                <div className="main-content-body">
                    <Trial />
                    {children}
                </div>
            </div>
        </section>
    );
};

export default Layout;
