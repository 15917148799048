import axios from "axios";
import * as classNames from 'classnames';
import React, {useEffect, useRef, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useLocation, useNavigate} from "react-router-dom";
import deleteSvg from "../../assets/images/delete.svg";
import plusIcon from "../../assets/images/plus.svg";
import videoOld from "../../assets/images/video_old.svg";
import Loader from "../../components/home/common/Loader";
import APP_ROUTES from "../../helper/app-routes";
import {base_url, base_url_v1, getLinkedProducts} from "../config/config";
import $ from 'jquery';
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import {NotificationManager} from "react-notifications";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

const Editor = () => {
    const navigate = useNavigate();
    var location = useLocation()
    const inputRef = useRef();
    const [source, setSource] = React.useState(null);
    const [uploadedVideo, setUploadedVideo] = useState([]);
    const [items, setItems] = useState([]);
    const [token, setToken] = useState("")
    const [selectedProduct, setSelectedProduct] = useState()
    const [editUrl, setEditUrl] = useState("")
    const [indicator, setIndicator] = useState(false);
    const canvaRef = useRef()
    const videoRef = useRef()
    const [disabled, setDisabled] = useState("disabled")
    const [videoName, setVideoName] = useState(location.state.title)
    const [mediaUrl, setMediaUrl] = useState("")
    const [pageName, setPageName] = useState(location.state.name)
    const [orientation, setOrientation] = useState("landscape")
    useEffect(() => {
        if (location.state.title === "Editor") {
            setVideoName(location.state.title)
        }
        const items = JSON.parse(localStorage.getItem("ItemList"));
        setSelectedProduct(items)
        let token = localStorage.getItem("token")
        setToken(token)
        if (items) {
            setItems(items);
        }
        if (!!location) {
            if (!!location.state) {
                if (location.state.name === "editor") {
                    if (location.state.flag === undefined) {
                        getVideoById()
                    }

                }
            }
        }
    }, []);

    useEffect(() => {
        const url = localStorage.getItem("videoUrl");
        setSource(url);
    }, []);

    //select file from system


    // $("input[type='submit']").click(function () {

    // });


    const handleFileChange = async (event) => {

        var $fileUpload = $("input[type='file']");
        if (parseInt($fileUpload.get(0).files.length) > 1) {
            NotificationManager.info("Only one file allowed")
        } else {
            const file = event.target.files[0];
            const url = URL.createObjectURL(file);
            localStorage.setItem("videoUrl", url);
            if (!videoName)
                setVideoName(event.target.files[0].name)

            setSource(url);
            await getVideoUrl(event.target.files[0].name, event.target.files);
        }
    };

    // convert image url to file
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    // delete product item

    const handleDeleteItem = (id) => {
        const item = items.filter((item) => item.id !== id);
        localStorage.setItem("ItemList", JSON.stringify(item));
        setItems(item);
    };

    //get video url from aws s3 bucket
    const getVideoUrl = async (params, file) => {
        setIndicator(true)
        var data = JSON.stringify({
            query: `query{
          videoUploadUrl(fileName:"${params}") {
            url
            fields{
              key
              value
            }
          }
        }`,
            variables: {},
        });

        var config = {
            method: "POST",
            url: base_url,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        await axios(config)
            .then(function (response) {
                createVideo(
                    response.data.data.videoUploadUrl.url,
                    response.data.data.videoUploadUrl.fields,
                    file
                );

            })
            .catch(function (error) {
                NotificationManager.info("Network Error. Please try again");

                setIndicator(false);
            });
    };

    function uuidv4() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    //upload url and get url to upload on s3

    const createVideo = async (url, params, file) => {
        setIndicator(true)

        var data = new FormData();
        params.forEach((element) => {
            data.append(element.key, element.value);
        });
        const videoFileName = file[0].name? file[0].name: `${uuidv4()}.mp4`;
        data.append("file", file[0]);
        data.append("fileName", videoFileName);
        var config = {
            method: "post",
            url: url,
            data: data,
        };

        await axios(config)
            .then(function (response) {
                generateThumbnail(file[0],videoFileName, params[0].value)
            })
            .catch(function (error) {
                setIndicator(false)
                localStorage.removeItem("videoUrl")
                NotificationManager.info("Network Error. Please try again");
            });
    };

    //generate thumbnail
    function generateThumbnail(file, videoFileName, videoObjectLocation) {
        const canvas = document.createElement('canvas');


        var _VIDEO = document.querySelector("#video"),
            _CANVAS_CTX = canvas.getContext("2d");

        canvas.width = _VIDEO.videoWidth;
        canvas.height = _VIDEO.videoHeight;

        // Placing the current frame image of the video in the canvas
        _CANVAS_CTX.drawImage(_VIDEO, 0, 0, _VIDEO.videoWidth, _VIDEO.videoHeight);
        //console.log("CanvasUrl", canvas.toDataURL())

        const fileThumbnail = dataURLtoFile(canvas.toDataURL(), videoFileName)
        uploadThumbnail(file, videoFileName, videoObjectLocation, fileThumbnail)
        // generateVideoThumbnails(file, 1).then((thumbnailArray) => {
        //   console.log("Thumbnail", file.name)
        //   const fileThumbnail = dataURLtoFile(thumbnailArray[0], file.name.split(".")[0])

        // }).catch((err) => {
        //   console.log("Catch", err);
        // })
    }

    //upload thumbnail
    const uploadThumbnail = (file, videoFileName, videoObjectLocation, thumbnailFile) => {

        var data = JSON.stringify({
            query: `query{
          thumbnailUploadUrl(fileName:"${thumbnailFile.name + "image"}") {
            url
            fields{
              key
              value
            }
          }
        }`,
            variables: {},
        });

        var config = {
            method: 'POST',
            url: base_url,
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                uploadPhoto(
                    response.data.data.thumbnailUploadUrl.url,
                    response.data.data.thumbnailUploadUrl.fields,
                    thumbnailFile,
                    file,
                    videoFileName,
                    videoObjectLocation,
                );
            })
            .catch(function (error) {
                setIndicator(false)
            });
    };

    //upload video
    const uploadPhoto = (url, params, thumbnailFile, videoFile, videoFileName, videoObjectLocation) => {
        var FormData = require('form-data');
        var data = new FormData();
        params.forEach(element => {
            data.append(element.key, element.value);
        });

        data.append("file", thumbnailFile)

        var config = {
            method: 'post',
            url: url,

            data: data
        };


        axios(config)
            .then(function (response) {
                createVideoMutationThumbnail(videoFile, videoFileName, videoObjectLocation, params[0].value)
            })
            .catch(function (error) {
                setIndicator(false)
                localStorage.removeItem("videoUrl")
            });

    };

    //upload video info to backend
    const createVideoMutationThumbnail = (file, videoFileName, videoObjectLocation, thumbnailObjectLocation) => {
        var data = JSON.stringify({
            query: `mutation{
          createVideo(
            input:{
            title:"${videoFileName}"
            description:"description"
            videoObjectLocation:"${videoObjectLocation}"
            thumbnailObjectLocation:"${thumbnailObjectLocation}"
          }
          ){
            id 
            title
            url
          }
        }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: base_url,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                const json = response.data.data.createVideo;
                setUploadedVideo(json)
                localStorage.setItem("finalVideoData", json.id)
                localStorage.setItem("videoUrl", json.url)

                setIndicator(false)
            })
            .catch(function (error) {
                setIndicator(false)
                localStorage.removeItem("videoUrl")
                console.log(error)
            });
    };

    //Link products to video api
    const renderVideo = (uploadedId) => {
        const token = localStorage.getItem("token")

        localStorage.removeItem("ItemList")

        localStorage.setItem("ItemList", JSON.stringify(items))

        let productsToLink = []

        if (items.length !== 0) {
            setIndicator(true)
            items.forEach(element => {
                if (element.variants === null) {
                } else {
                    let id = element?.variants[0]?.id
                    productsToLink.push({
                        productId: element.id,
                        timestamp: 2,
                        variantId: id,
                    });
                }

            });

            var data = JSON.stringify({
                query: `mutation CreateProduct($input: [LinkedProductInput!]!) {
                linkProductsToVideo(input: $input, id: "${uploadedId}") {
                  id
                  title
                  description
                  thumbnail
                }
              }`,
                variables: {input: productsToLink},
            });

            var config = {
                method: 'post',
                url: base_url,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    const json = response.data.data.linkProductsToVideo.thumbnail
                    localStorage.setItem("thumbnail", json)
                    updateVideoMutation(uploadedId)
                    // navigate(APP_ROUTES.RENDERED, { replace: true }, { state: { id: location.state.id } });
                })
                .catch(function (error) {
                    setIndicator(false)
                    NotificationManager.info("Network Error. Please try again");
                });
        } else {
            NotificationManager.info("Please add products first");

        }
    };

    //Get video and linked products
    function getVideoById() {
        const token = localStorage.getItem("token")
        setIndicator(true)
        const id = location.state.id
        var data = {
            "id": id
        }

        var config = {
            method: 'post',
            url: getLinkedProducts,
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                const json = response.data.data.video;
                setSource(json.url)
                let prod = [...items];
                json.linkedProducts.forEach((item, index) => {
                    prod.push(item.product);
                });
                localStorage.setItem("ItemList", JSON.stringify(prod))
                localStorage.setItem("videoUrl", json.url)
                setItems(prod);
                setIndicator(false)
                setVideoName(json.title)

            })
            .catch(function (error) {
                setIndicator(false)
            });
    }

    //Save Edited video
    const EditRenderVideo = async () => {

        localStorage.removeItem("ItemList")

        localStorage.setItem("ItemList", JSON.stringify(items))

        let productsToLink = []
        // setDisabledSubmit(true)
        if (items.length !== 0) {
            setIndicator(true)
            items.forEach(element => {
                if (element.variants === null) {

                } else {
                    let id = element?.variants[0]?.id
                    productsToLink.push({
                        productId: element.id,
                        timestamp: 2,
                        variantId: id,
                    });
                }

            });

            var data = JSON.stringify({
                query: `mutation CreateProduct($input: [LinkedProductInput!]!) {
                linkProductsToVideo(input: $input, id: "${location.state.id}") {
                  id
                  title
                  description
                }
              }`,
                variables: {input: productsToLink},
            });

            var config = {
                method: 'post',
                url: base_url,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    updateVideoMutation(location.state.id)
                })
                .catch(function (error) {
                    setIndicator(false)
                });
        }
    };

    const updateVideoMutation = (videoId) => {

        let name = ''
        if (videoName !== '' && videoName !== undefined) {
            name = videoName
        } else if (location.state.title !== '' && location.state.title !== undefined) {
            name = location.state.title
        } else {
            name = "Video " + (location.state.videoCount + 1)
        }

        console.log(name)


        var data = JSON.stringify({
            query: `mutation{
          updateVideo(
            id:"${videoId}"
            input:{
            title:"${name}"
            locked:0
          }
          ){
            id 
            title
            url
          }
        }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: base_url,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setIndicator(false)
                navigate(APP_ROUTES.RENDERED, {replace: true}, {state: {id: videoId}});
            })
            .catch(function (error) {
                setIndicator(false)
                NotificationManager.info("Network Error. Please try again");
            });

    }

    //Add products to array
    const addProductList = () => {
        if (location.state.name === "editor") {
            localStorage.getItem("dropdownValue")
            navigate(APP_ROUTES.PRODUCT_LIST, {
                state: {
                    id: location.state.id,
                    name: location.state.name,
                    title: videoName,
                    videoCount: location.state.videoCount
                }
            });
        } else {
            localStorage.getItem("dropdownValue")
            navigate(APP_ROUTES.PRODUCT_LIST,
                {
                    state: {
                        id: uploadedVideo.id !== undefined ?
                            uploadedVideo.id : location.state.id, name: location.state.name,
                        flag: false, title: videoName,
                        videoCount: location.state.videoCount
                    }
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setPageName("NoLoader")

        if (source !== null) {
            if (location?.state?.name === "editor") {
                //videoRef?.current?.plyr.destroy()
                EditRenderVideo()
            } else {
                renderVideo(location.state.id !== undefined ? location.state.id : uploadedVideo.id)
            }
        } else {
            NotificationManager.info("Please select a video");
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        let itemsOrder = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(itemsOrder)
    }

    function handleOnChangeInput(e) {
        setVideoName(e.target.value)

    }

    function handleOnChangeMediaUrlInput(e) {
        setMediaUrl(e.target.value)

    }

    const  handleGrabSocialMedia = (async e => {
        let url = mediaUrl
        let videoId = ''
        let feedUrl = ''
        if (url.includes("instagram") || url.includes("facebook")){
            setIndicator(true)
            fetch(`${base_url_v1}/media/get-video?u=${url}`).then(response => response.json()).then(data => {

                // Extract video URLs from the HTML using the appropriate selectors
                const videoUrl = `${base_url_v1}/proxy/${data.body.video}`;
                fetch(`${base_url_v1}/proxy/${data.body.video}`, {
                    method: 'GET',
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        // Create blob link to download
                        const file = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        localStorage.setItem("videoUrl", file);

                        setSource(file);
                        getVideoUrl('media.mp4', [file]);
                    });

            })

        } else if (url.includes("youtube")) {
            NotificationManager.info("Youtube video currently not supported");
        } else if (url.includes("youtu.be")) {
            NotificationManager.info("Youtube video currently not supported");
        } else {
            NotificationManager.alert("Please enter a valid URL");
        }


        //setSource(editUrl);
    });


    useEffect(() => {
        if (source !== null) {
            const video = videoRef?.current;
            video?.addEventListener('loadedmetadata', () => {
                const width = video.videoWidth;
                const height = video.videoHeight;

                // Set the orientation based on width and height
                const videoOrientation = width > height ? 'landscape' : 'portrait';
                setOrientation(videoOrientation);
            });

        }
    }, [source]);
    /*
        const videoOptions = {
            controls: [
                'play-large', // The large play button in the center
                'progress', // The progress bar and scrubber for playback and buffering
                'current-time', // The current time of playback
                'duration', // The full duration of the media
                'mute', // Toggle mute
                'volume', // Volume control
            ],
            loop: {active: true},
            playsinline: true,
            autoplay: true,
        };
        const plyrVideo =
            source ? (
                <Plyr
                    ref={videoRef}
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: source,
                                provider: "html5"
                            }
                        ]
                    }}
                    options={videoOptions}
                />
            ) : null;
    */
    //Layout Editor
    return (
        <div className="wrapper">

            <div className="header">
                <h3 className="text-white text-center font-weight-normal headtitle-3">
                    Editor
                </h3>
            </div>

            <div className="content" style={{
                justifyContent: "flex-start",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center"
            }}>
                {indicator ?<Loader/>: ""}
                <div className="videoDetails">

                    <div className="product-page-video">



                        {source === null ? (
                            <div className="w-100 h-100">
                                <Tabs>
                                    <TabList>
                                        <Tab>My Drive</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div
                                            className="mx-auto text-center py-5 h-100 d-flex justify-content-center flex-column"
                                            style={{background: "black", padding: "15px"}}>

                                            <button className="create-video-btn" onClick={() => {
                                                inputRef.current.click();
                                            }}>
                                                <input
                                                    style={{display: "none"}}
                                                    name="live"
                                                    type="file"
                                                    className="input-addproduct"
                                                    multiple
                                                    ref={inputRef}
                                                    onChange={handleFileChange}
                                                    accept="video/mp4,video/mkv,video/x-m4v"
                                                ></input>
                                                <img src={videoOld} alt="" className="edit-add-icon"/>

                                                Select Video
                                            </button>
                                        </div>
                                    </TabPanel>

                                </Tabs>

                            </div>
                        ) : (
                            <div style={{margin: "0 auto", width: orientation === "landscape" ? "100%" : "300px"}}>
                                {/*{plyrVideo}*/}
                                <video
                                    playsInline={1}
                                    preload="metadata"
                                    ref={videoRef}

                                    id="video"
                                    disablePictureInPicture
                                    controlsList="nofullscreen nodownload  noplaybackrate foobar"
                                    src={source}
                                    controls
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                ></video>

                            </div>
                        )}

                    </div>

                    <div className="well"
                         style={{marginTop: "20px", width: "100%", height: "fit-content"}}>
                        <form>
                            <p className="text-white text-left">Title</p>
                            <input placeholder="Enter video name here" value={videoName} onChange={handleOnChangeInput}
                                   className="form-control" type="text" maxLength={35}></input>
                            {/* <i className="fa-sharp fa-solid fa-pen-to-square custom-icon"></i> */}

                        </form>
                        <div style={{marginTop: "20px"}} className="bottom-products">
                            <hr className="bg-secondary mt-2 mb-0 mt-md-0"/>
                            <p className="text-white mb-0">Products</p>
                            <div className="select-product-slider d-flex thin-scroll">
                                <div
                                    className="add-product-box thumb-same mt-3 mb-2"
                                    onClick={() => addProductList(location.state.name)}
                                >
                                    <img id="download-link" name="image" src={plusIcon} alt=""/>
                                    <span className="text-white">Add Products</span>
                                </div>


                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable direction={'horizontal'} droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                className={classNames('list thin-scroll pt-3 pb-2', snapshot.isDraggingOver && 'draggingOver')}
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {items.map((item, index) => (
                                                    <Draggable key={item} draggableId={`${index}-id`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={classNames('product-selected thumb-same', snapshot.isDragging && 'dragging')}
                                                                style={provided.draggableProps.style}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <button
                                                                    className="delete-btn linkbtn"
                                                                    onClick={() => {
                                                                        handleDeleteItem(item.id);
                                                                    }}
                                                                >
                                                                    <img src={deleteSvg} alt=""/>
                                                                </button>
                                                                {item?.image !== null ?
                                                                    < img src={item?.image} alt=""/> : null
                                                                }
                                                            </div>
                                                        )}

                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="footer">

                <div
                    className="col-6 px-1"
                    onClick={() => {
                        localStorage.removeItem("videoUrl");
                        localStorage.removeItem("ItemList")
                        navigate(APP_ROUTES.DASHBOARD);

                    }}
                >
                    <a className="btn btn-red-color radius-8 w-100 py-3">
                        Back
                    </a>
                </div>
                <div className="col-6 px-1">
                    <button
                        type="submit"
                        className="btn  radius-8 w-100 py-3 text-black bg-white"
                        onClick={handleSubmit}
                    >
                        Publish
                    </button>
                </div>


            </div>
        </div>
    );
};

export default React.memo(Editor);
