//Api urls

export const base_url = `${process.env.REACT_APP_API_URL}/graphql`
export const base_url_v1 = process.env.REACT_APP_PROXY_URL

//Api endpoints
export const getChargeDetails = `${base_url_v1}/get-charge-details_v2`
export const getShopifyCollection = `${base_url_v1}/get-shopify-product-collection`
export const buySubscriptionUrl = `${base_url_v1}/buy-subscription`
export const getShopifyCollections = `${base_url_v1}/get-shopify-collection`
export const getLinkedProducts = `${base_url_v1}/get-eu-video-and-product-data`
export const getAccessTokenUrl = `${base_url_v1}/get-access-token`
export const getShopDetailsUrl = `${base_url_v1}/get-shop-details`
export const updateUserSubscription = `${base_url_v1}/update-user-subscription`
export const cancelSubscriptionUrl = `${base_url_v1}/cancel-subscription`

//Api Keys Related to App

export const SHOPIFY_API_KEY = process.env.REACT_APP_SHOPIFY_API_KEY
export const SHOPIFY_API_SECRET = process.env.REACT_APP_SHOPIFY_API_SECRET


//For Prod Setup
export const redirectURL = `${process.env.REACT_APP_SHOPIFY_URL}/shopify-api/callback`
export const redirectURLLogin= `${process.env.REACT_APP_SHOPIFY_URL}/login-api/callback`
export const chargeRedirectAPI = `${process.env.REACT_APP_SHOPIFY_URL}/returnUrl`
export const renewRedirectAPI = `${process.env.REACT_APP_SHOPIFY_URL}/dashboard`


export const SCOPES = "read_products";

