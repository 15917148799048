import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './lib/reactifyCss';
import reportWebVitals from './reportWebVitals';
import ErrorFallBack from './error-boundry'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '../src/custom-css/custom-style.css'
import { NotificationContainer } from 'react-notifications';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={ErrorFallBack} >
    <NotificationContainer />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
