/**
 * App.js Layout Start Here
 */
import React, { Component, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DEVELOPMENT_CONFIG from '../helper/config';
import APP_ROUTES from '../helper/app-routes';



function Protected(props) {
    const { Component } = props;
    const navigate = useNavigate();

    useEffect(() => {
        // let token = true;
        // if (!token) {
        //     navigate(APP_ROUTES.LOGIN)
        // }
    }, [])

    return (
        <div>
            <Component />
        </div>
    );
}


export default Protected;



