import { ThreeDots } from 'react-loader-spinner'

const Loader = (props) => {
  return (
    <div className="topcontentload" style={{ width: '100%',flexDirection:'column', background: '##ffffff21', position: 'fixed', inset:'0px auto', zIndex: '1055', height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <ThreeDots color="red" height={80} width={80} />
    </div>
  )
}

export default Loader;