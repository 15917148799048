const DEVELOPMENT_CONFIG = {
    TOKEN: 'token',
    ACCESS_TOKEN: 'access_token',
    CHARGE_ID: 'charge_id',
    REGISTRATION_INFO: 'reg_info',
    LOGIN_INFO: 'login_info',
    BILLING_ON: 'billing_on',
    SHOP: 'shop',
    FIELDS: 'fields',
    REDO_OAUTH: 'redo_oauth',
    IS_SUBSCRIBED:'is_subscribed',
    IS_SHOPIFY_LOGIN:'is_shopify_login',
    PACKAGE: 'package',
    DAYS:"days",
    AFFILIATE_CODE:"affiliate_code",

}
export default DEVELOPMENT_CONFIG;