import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { base_url, base_url_v1, chargeRedirectAPI, getAccessTokenUrl, getShopDetailsUrl, SHOPIFY_API_KEY, SHOPIFY_API_SECRET } from "../config/config";
import DEVELOPMENT_CONFIG from "../../helper/config";
import { useNavigate } from "react-router-dom";
import APP_ROUTES from "../../helper/app-routes";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/home/common/Loader";
import moment from 'moment';

const ShopifyApiCallBack = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let code = searchParams.get("code");
  let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)

  const [indicator, setIndicator] = useState(false)

  useEffect(() => {
    let code = searchParams.get("code");

    let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)

    let regInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.FIELDS))

    if (shopifyToken !== null) {
      if (regInfo !== null) {
        navigate(APP_ROUTES.DASHBOARD)
      } else {
        navigate(APP_ROUTES.REGISTER)
      }

    } else {
      getAccessToken(code);
    }

  }, []);


  const buySubscriptionOAuth = (fields) => {
    let shopify_token = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
    var data = JSON.stringify({
      "shopify_token": shopify_token,
      "package_details": {
        "name": fields.package === "monthly" ? "Basic Monthly" : "Basic Yearly",
        "returnUrl": chargeRedirectAPI,
        "test": true,
        "trialDays": 14,
        "lineItems": [
          {
            "plan": {
              "appRecurringPricingDetails": {
                "price": {
                  "amount": fields.package === "monthly" ? 9 : 99,
                  "currencyCode": "USD"
                },
                "interval": fields.package === "monthly" ? "EVERY_30_DAYS" : "ANNUAL"
              }
            }
          }
        ]
      },
      "shop_name": fields.shop_name
    });
    var config = {
      method: 'post',
      url: base_url_v1,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setIndicator(false)
        let confirmationUrl = response.data.body.data.appSubscriptionCreate.confirmationUrl
        localStorage.setItem(DEVELOPMENT_CONFIG.REGISTRATION_INFO, JSON.stringify(fields))
        window.location.replace(confirmationUrl)
      })
      .catch(function (error) {
        setIndicator(false)
        NotificationManager.error(error.message)
      });
  }

  const getShop = async (accessToken, storeSubdomain) => {
    const shop = {
      currency: "GBP",
      taxes_included: true,
      money_format: "£{{amount}}"
    }

    var data = JSON.stringify({
      accessToken: accessToken,
      storeSubdomain: storeSubdomain,
    });

    var config = {
      method: "post",
      url: getShopDetailsUrl,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const response = await axios(config);
      shop.currency = response.data.data.shop.currency
      shop.taxes_included = response.data.data.shop.taxes_included
      shop.money_format = response.data.data.shop.money_format

    } catch( error) {
            console.log(error);
    }

    return shop
  }


  const registration = async (regInfo) => {
    let shopifyToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
    let affiliateCode = localStorage.getItem(DEVELOPMENT_CONFIG.AFFILIATE_CODE)
    if (affiliateCode === null || affiliateCode === "null" || affiliateCode === undefined || affiliateCode === "undefined") {
      affiliateCode = ""
    }
    let today = new Date()
    let trialStartDate = moment(today).format('YYYY-MM-DD')
    let trialEndDate = moment(today).add(process.env.REACT_APP_TRIAL_DAYS, 'days').format('YYYY-MM-DD')

    const shop = await getShop(shopifyToken, regInfo.shop_name)


    var data = JSON.stringify({
      query: `mutation {
                  register(
                    input: {
                      username:  "${regInfo.username}"
                      email:  "${regInfo.email}"
                      name:  "${regInfo.first_name}"
                      lastName:  "${regInfo.last_name}"
                      companyName:  "${regInfo.company_name}"
                      shopifyApiInfo: {
                        storeSubdomain: "${regInfo.shop_name}"
                        accessToken:  "${shopifyToken}"
                        chargeId: "${""}"
                        billingOnDate: "${""}"
                        is_shopify_login:${1},
                        is_subscribed:${0},
                        trial_start:"${trialStartDate}"
                        trial_end:"${trialEndDate}",
                        subscription_start:"${""}"
                        subscription_end:"${""}",
                        package:"${""}",
                        affiliateCode:"${affiliateCode}",
                        currency:"${shop.currency}",
                        taxes_included:${shop.taxes_included},
                        money_format:"${shop.money_format}",
                      }
                    }
                  )
                }`,
      variables: {}
    });

    var config = {
      method: 'post',
      url: base_url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
        .then(function (response) {
          if (response.data.data.register === true) {
            // remove affiliate code from local storage
            localStorage.removeItem(DEVELOPMENT_CONFIG.AFFILIATE_CODE)
            navigate(APP_ROUTES.LOGIN)
            NotificationManager.success("Registration Successful")
          } else {
            navigate(APP_ROUTES.REGISTER)
            setIndicator(false)
            NotificationManager.error("User Already Exists , Please use a unique Username and Email")
          }
        })
        .catch(function (error) {
          navigate(APP_ROUTES.REGISTER)
          setIndicator(false)
          NotificationManager.error("An error occurred. Please try again")
        });

  }


  const getAccessToken = (code) => {

    let accessToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
    let regInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.FIELDS))
    let shopName = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)
    if (accessToken === null) {
      setIndicator(true)
      var data = JSON.stringify({
        client_id: SHOPIFY_API_KEY,
        client_secret: SHOPIFY_API_SECRET,
        code: code,
        shop_name: shopName !== null ? shopName : regInfo.shop_name,
      });

      var config = {
        method: "post",
        url: getAccessTokenUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(async function (response) {

          localStorage.setItem(
              DEVELOPMENT_CONFIG.ACCESS_TOKEN,
              response.data.body.access_token
          );

          if (regInfo !== null) {
            await registration(regInfo)
          } else {
            navigate(APP_ROUTES.REGISTER)
          }
        })
        .catch(function (error) {
          setIndicator(false)
          NotificationManager.error(error.message);
        });
    } else {
      buySubscriptionOAuth(regInfo)
    }

  };

  return (
    <>
      {indicator && <Loader />}

    </>
  )
};

export default ShopifyApiCallBack;

