import axios from 'axios';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import deleteSvg from "../../assets/images/delete.svg";

import step1 from "../../assets/images/instructions-1.png";
import step2 from "../../assets/images/instructions-2.png";
import step3 from "../../assets/images/instructions-3.png";
import Loader from '../../components/home/common/Loader';
import APP_ROUTES from '../../helper/app-routes';
import DEVELOPMENT_CONFIG from '../../helper/config';
import { base_url, getChargeDetails } from '../config/config';
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'react-tooltip';

const CraeteVideo = () => {
  const navigate = useNavigate();
  const [myVideos, setMyVideos] = useState([])

  const [videoCount, setvideoCount] = useState(-1)
  const [visibility, setVisibility] = useState(-1)
  const [indicator, setIndicator] = useState(false)

  const handleViewVideo = (id, thumbnail, locked, title) => {
    localStorage.setItem("finalVideoData", id)
    localStorage.setItem("locked", locked)
    localStorage.setItem("thumbnail", thumbnail)
    navigate(APP_ROUTES.RENDERED, { state: { id: id, name: "editor", title: title, videoCount: myVideos.length } });
  }

  const handleEditVideo = (id, thumbnail, title) => {
    localStorage.setItem("finalVideoData", id)
    localStorage.setItem("thumbnail", thumbnail)
    navigate(APP_ROUTES.EDITOR, { state: { id: id, name: "editor", title: title, videoCount: myVideos.length } });
  }

  const handlePreviewVideo = (id, thumbnail) => {
    localStorage.setItem("finalVideoData", id)
    localStorage.setItem("thumbnail", thumbnail)

    localStorage.setItem("returnPage", APP_ROUTES.DASHBOARD)
    navigate(APP_ROUTES.PREVIEW);
  }

  const handlePreviewExternal = (finalVideoData) => {
    window. open(`${process.env.REACT_APP_VIDEO_URL}/video/${finalVideoData}`, '_blank', 'noreferrer')
  };


  const handleActionFocus = (e) => {
    var container = e.currentTarget;
    container.querySelector('.actions').classList.add("active");
  };

  const handleActionBlur = (e) => {
    var container = e.currentTarget;
    container.querySelector('.actions').classList.remove("active");
  };





  function getSubscription() {
    let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
    let accessToken = localStorage.getItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
    let chargeId = localStorage.getItem(DEVELOPMENT_CONFIG.CHARGE_ID)

    var data = JSON.stringify({
      "shopify_token": accessToken,
      "chargeId": chargeId,
      "shop_name": loginInfo.shopifyApiInfo.storeSubdomain,
      "user_id": loginInfo.id
    });

    var config = {
      method: 'post',
      url: getChargeDetails,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        const subscription1 = response.data.body
        localStorage.setItem(DEVELOPMENT_CONFIG.PACKAGE, subscription1.recurring_application_charge.name)
        if (response.data.body.days <= 0) {
          if (JSON.stringify(response.data.body.subscriptionDetails) === "{}") {
            setVisibility(0)
          } else if (dataToString(currentDate()) > dataToString(response.data.body.subscriptionDetails.last_date)) {
            setVisibility(0)
          } else {
            setVisibility(1)
          }
        }
        getVideos()
      })
      .catch(function (error) {
        if (error.response.data.message === "Please enter all required fields. ") {
          setVisibility(0)
          navigate(APP_ROUTES.DASHBOARD)
        } else {
          userupdate()
        }
      });
  }


  const userupdate = () => {
    console.log("worked or not")
    let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
    var data = JSON.stringify({
      query: `mutation{
      userupdate(
        input:{
          username:"${loginInfo.username}",
          shopifyApiInfo:{
            is_shopify_login:${0}
            is_subscribed:${1}
          }
        }
      )
    }`,
      variables: {}
    });

    var config = {
      method: 'post',
      url: base_url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        localStorage.removeItem(DEVELOPMENT_CONFIG.TOKEN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.IS_SHOPIFY_LOGIN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)
        localStorage.removeItem(DEVELOPMENT_CONFIG.LOGIN_INFO)
        localStorage.removeItem(DEVELOPMENT_CONFIG.ACCESS_TOKEN)
        localStorage.removeItem(DEVELOPMENT_CONFIG.BILLING_ON)
        localStorage.removeItem(DEVELOPMENT_CONFIG.CHARGE_ID)
        localStorage.removeItem(DEVELOPMENT_CONFIG.PACKAGE)
        localStorage.removeItem("dropdown")
        localStorage.removeItem(DEVELOPMENT_CONFIG.FIELDS)
        localStorage.removeItem("videoUrlId")
        localStorage.removeItem(DEVELOPMENT_CONFIG.DAYS)
        navigate(APP_ROUTES.LOGIN)
        NotificationManager.error("Session Expired ,Please install our app again to get our amazing features")
      })
      .catch(function (error) {
        alert("Network Error")
        console.log(error);
      });

  }

  const getVideos = () => {
    setIndicator(true)
    let token = localStorage.getItem("token")
    var data = JSON.stringify({
      query: `query{
              videos{
                id
                title
                url
                locked
                createdAt
                thumbnail
               
                user{
                  shopifyApiInfo{
                    storeSubdomain
                    accessToken
                    chargeId
                  }
                }
              }
              
            }`,
      variables: {},
    });

    var config = {
      method: 'post',
      url: base_url,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        const json = response.data;
        let videosArray = json.data.videos
        let count = videosArray.length
        setvideoCount(count)
        for (let index = 0; index < videosArray.length; index++) {
          videosArray[index]["count"] = count;
          count = count - 1;
        }
        setMyVideos(videosArray);

        setIndicator(false)
      })
      .catch(function (error) {
        setIndicator(false)
      });
  }

  function removeVideoFromList(item) {
    let videos = [...myVideos];

    const index = videos.indexOf(item);
    if (index > -1) {
      videos.splice(index, 1);
    }

    setMyVideos(videos);
  }

  function deleteVideo(id, item) {
    let token = localStorage.getItem("token")

    setIndicator(true)
    var data = JSON.stringify({
      query: `mutation{
                    deleteVideo(id:"${id}")
                        }`,
      variables: {},
    });

    var config = {
      method: 'post',
      url: base_url,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setIndicator(false)
        removeVideoFromList(item);
        setvideoCount(videoCount - 1);
      })
      .catch(function (error) {
        setIndicator(false)
      });
  }

  //Convert date to timestamp
  function dataToString(today) {
    var datum = Date.parse(today);
    return datum / 1000;
  }

  //Get today's date
  function currentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }


  useEffect(() => {
    let isSubs = localStorage.getItem(DEVELOPMENT_CONFIG.IS_SUBSCRIBED)

    if (isSubs !== "0") {
      setVisibility(1)
      getSubscription()
    } else {
      let daysLeft = localStorage.getItem(DEVELOPMENT_CONFIG.DAYS)
      if (daysLeft !== "0") {
        setVisibility(1)
        getVideos()
      } else {
        setVisibility(0)
      }

    }

    localStorage.removeItem("videoUrl")
    localStorage.removeItem("ItemList")
    localStorage.removeItem("finalVideoData")
    localStorage.removeItem("thumbnail")

  }, [])

  const handleCreateVideo = () => {
    navigate(APP_ROUTES.EDITOR, { state: { name: "createVideo", title: '', videoCount: myVideos.length } })
  }

  return (

        <div className="wrapper">
          <div className="header">
          <h3 className="text-white text-center font-weight-normal headtitle-3">
            Dashboard
          </h3>
          </div>
          {
            visibility === 1 ? <>
              <div className="content">
              <div className="dashboard-videos-grid">
                {indicator ? <Loader /> : ""}
                {(videoCount > 0)?
                  myVideos.map((item) => {
                    return (
                      <div className="dashbaord-video">

                        <div className="upper-dash" onMouseEnter={handleActionFocus} onMouseLeave={handleActionBlur}>
                          <div className="lock" style={{display: item?.locked? "flex" : "none"}}>
                            <i className="fa-solid fa-lock"></i>
                          </div>
                          <img src={item?.thumbnail} className='thumbimg' alt={item?.title} />
                          <div className="actions">
                            <button className="actionbtn" data-tooltip-id="publish-action" data-tooltip-content="Publish" onClick={() => { handleViewVideo(item.id, item.thumbnail, item.locked, item.title) }}>
                              <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            </button>
                            <Tooltip id="publish-action" />
                            <button style={{display: !item?.locked? "block" : "none"}} className="actionbtn" data-tooltip-id="edit-action" data-tooltip-content="Edit" onClick={() => { handleEditVideo(item.id, item.thumbnail, item.title) }}>
                              <i className="fa-solid fa-pencil-square"></i>
                            </button>
                            <Tooltip id="edit-action" />
                            <button  className="actionbtn" data-tooltip-id="preview-action" data-tooltip-content="Preview" onClick={() => { handlePreviewExternal(item.id) }}>
                              <i className="fa-solid fa-play"></i>
                            </button>
                            <Tooltip id="preview-action" />
                            <button style={{display: !item?.locked? "block" : "none"}} className="actionbtn" data-tooltip-id="delete-action" data-tooltip-content="Delete" onClick={() => deleteVideo(item.id, item)}>
                              <i className="fa-solid fa-trash"></i>
                            </button>
                            <Tooltip id="delete-action" />
                          </div>
                        </div>
                        <div className="lower-dash">
                          <p className="text-white video-text new-video-title">{item.title}</p>
                          <div className="lower-dash-inner">
                            <p className="text-white new-video-text"> {Moment(item.createdAt).format('Do MMM yyyy')}</p>
                            <p className="text-white new-video-text"> {Moment(item.createdAt).format('hh:mm a')}</p>
                          </div>
                        </div>
                      </div>
                    )
                  }) : videoCount === 0 ?
                    <div  className="dashboard-instructions">
                      <div className="text-white text-center font-weight-normal" style={{marginBottom:'20px'}}>Get creating your shoppable video below, it's as easy as 1, 2, 3.</div>
                      <div className="dashboard-instructions-wrapper">
                        <div>
                        <div className="text-white text-center font-weight-normal">1. Create </div>
                        <img src={step1} alt="step1" />
                        </div>
                        <div>
                        <div className="text-white text-center font-weight-normal">2. Share</div>
                        <img src={step2} alt="step2" />
                        </div>
                        <div>
                        <div className="text-white text-center font-weight-normal">3. Sell</div>
                        <img src={step3} alt="step3" />
                        </div>
                      </div>
                    </div> : ""
                }
              </div>
                </div>
              <div className="footer">

                <button className="create-video-btn" onClick={handleCreateVideo} > Create new Video</button>

                </div>
            </>
              : visibility === 0 ?
              <div>
                <h4 style={{ color: 'red', marginTop: 30, textAlign: 'center' }}>
                  You don't have any active subscription. Please buy a subscription first.
                </h4>
              </div>
                : ""
          }
        </div>


  )
}

export default CraeteVideo;