import axios from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import Loader from "../../components/home/common/Loader";
import APP_ROUTES from "../../helper/app-routes";
import DEVELOPMENT_CONFIG from "../../helper/config";
import {getShopifyCollection, getShopifyCollections} from "../config/config";


const ProductList = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [collectionList, setCollectionList] = useState([]);
    const [indicator, setIndicator] = useState(false);
    const [product, setRequestProductsResponse] = useState([]);
    const [visibility, setVisibility] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [buttonText, setButtonText] = useState("Select All");
    const [DropDownItem, setDropDownItem] = useState();
    const selectedProductIds = selectedProducts.map(prod => parseInt(prod.id));
    const currentProductsIds = product.map(prod => prod.id);
    const isAllSelected = currentProductsIds.every(v => selectedProductIds.includes(v))
    const [filterItemValue, setFilterItemValue] = useState("all")

    const [filterList, setFilterList] = useState(
        [
            {
                title: 'All',
                value: 'all'
            },
            {
                title: 'Active',
                value: 'active'
            },
            {
                title: 'Draft',
                value: 'draft'
            },
            {
                title: 'Archived',
                value: 'archived'
            }
        ]
    )

    const [filterOption, setFilterOption] = useState("all")


    useEffect(() => {
        shopifyCollections();
        const newList = JSON.parse(localStorage.getItem("ItemList"));
        const newDropDownList = JSON.parse(localStorage.getItem("dropdown"));
        if (newList !== null) {
            setSelectedProducts(newList);
        }
        if (newDropDownList !== null) {
            setDropDownItem(newDropDownList);
        }
    }, []);


    useEffect(() => {
        shopifyCollectionsSingular(DropDownItem, filterItemValue);
    }, [DropDownItem]);

    function shopifyCollections() {
        setIndicator(true)
        let token = localStorage.getItem("token");
        let login = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        var data = {
            "user_id": login.id
        }

        var config = {
            method: "post",
            url: getShopifyCollections,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                const json = response.data.data.shopifyCollections;
                setCollectionList(json);
                let newDropDownList = JSON.parse(localStorage.getItem("dropdown"));
                if (newDropDownList !== null) {
                    shopifyCollectionsSingular(newDropDownList, filterItemValue)
                } else {
                    shopifyCollectionsSingular(json[0].id, filterItemValue)
                }

            })
            .catch(function (error) {

                // setIndicator(false)

            });
    }

    function shopifyCollectionsSingular(params, filterValue) {
        setIndicator(true)
        let loginInfo = JSON.parse(localStorage.getItem(DEVELOPMENT_CONFIG.LOGIN_INFO))
        let shop = localStorage.getItem(DEVELOPMENT_CONFIG.SHOP)
        let token = localStorage.getItem("token");
        var data = {
            "id": params,
            "user_id": loginInfo.id,
            "status": filterValue
        }

        var config = {
            method: "post",
            url: getShopifyCollection,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                const json = response.data.data.shopifyCollection;
                setRequestProductsResponse(json.products)
                setIndicator(false);
            })
            .catch(function (error) {
                setIndicator(false);
            });
    }


    let optionItems = collectionList.map((item) => (
        <option value={item.id} key={item.id}>
            {item.title}
        </option>
    ));

    let optionItemsFilter = filterList.map((item) => (
        <option value={item.value} key={item.title}>
            {item.title}
        </option>
    ));


    function handleOnSelectProducts(dataObj) {
        let dataObj2 = dataObj;
        let products = [...selectedProducts];
        const productIndex = products.findIndex(
            (product) => parseInt(product.id) === dataObj.id
        );
        if (productIndex !== -1) {
            if (productIndex > -1) {
                products.splice(productIndex, 1);
                setVisibility(false);
            }
        } else {
            products.push(dataObj2);
        }

        setSelectedProducts(products);
    }

    const handleSelecteAll = (e) => {
        e.preventDefault()

        let newArray = [...selectedProducts]

        product.forEach(element => {
            if (selectedProductIds.indexOf(element.id) < 0) {
                newArray.push(element)
            }

        });
        let buttonText = "Unselect All"

        let newArrayUpdated = removeDuplicates(newArray)
        setSelectedProducts(newArrayUpdated)

        setButtonText(buttonText);
        setVisibility(false);

    };


    function removeDuplicates(arr) {
        return arr.filter((item,
                           index) => arr.indexOf(item) === index);
    }

    const handleUnSelecteAll = (e) => {
        e.preventDefault();

        let updatedProducts = selectedProducts.filter(function (el) {
            if (!currentProductsIds.includes(parseInt(el.id))) {
                return el;
            }
        });
        setSelectedProducts(updatedProducts);
        setVisibility(true);
        setButtonText("Select All");
    };


    const handleChangeFilter = (e) => {
        let value = Array.from(e.target.selectedOptions, (option) => option.value);
        setFilterOption(value)
        setFilterItemValue(value)
        shopifyCollectionsSingular(DropDownItem, value[0])
    };

    const handleChange = (e) => {
        let value = Array.from(e.target.selectedOptions, (option) => option.value);
        setDropDownItem(value);
        localStorage.setItem("dropdown", value);
        setFilterOption("all")
        shopifyCollectionsSingular(value[0], "all");
    };

    const handleSelected = (e) => {
        e.preventDefault()
        localStorage.setItem("ItemList", JSON.stringify(selectedProducts));
        navigate(APP_ROUTES.EDITOR, {
            state: {
                id: location.state.id,
                name: location.state.name,
                flag: false,
                title: location.state.title,
                videoCount: location.state.videoCount
            }
        });
    };

    return (


        <div className="wrapper">
            <div className="header">
                <h3 className="text-white text-center font-weight-normal headtitle-3">
                    Select Products
                </h3>
                <form action="" style={{width: '100%'}}>
                    <div style={{justifyContent:"center"}} className="d-md-flex align-items-end custom-select-drop">
                        <div className="col-lg-4 col-md-4 px-0 mb-lg-4 mb-2">
                            <label className="text-white text-md-left text-left d-block">
                                Product Library
                            </label>
                            <select
                                value={DropDownItem}
                                onChange={handleChange}
                                className="form-control select-product"
                            >
                                {optionItems}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 px-0 mb-lg-4 mb-2"><label
                            className="text-white text-left d-block">Filters</label>
                            <select value={filterOption} id="select" onChange={handleChangeFilter}
                                    className="form-control select-product">{optionItemsFilter}</select>

                        </div>
                        <div className="col-lg-2 col-md-2 px-0 mb-lg-4 mb-1">
                            <button
                                className="btn-select-all"
                                onClick={
                                    !isAllSelected ? handleSelecteAll : handleUnSelecteAll
                                }
                            >
                                {!isAllSelected ? "Select All" : "UnSelect All"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>


            <div className="content mt-2 pt-3 scrolling-products thin-scroll" style={{display: 'initial'}}>


                {indicator? "" : product.length !== 0 ?
                    <div style={{width: '100%'}} className="product-column-grid">

                        {product.map((itemProduct) => {

                            return (
                                <label
                                    key={itemProduct.id}
                                    htmlFor={itemProduct.id}
                                    className="product-column"
                                >
                                    <img src={itemProduct?.images[0]?.src} alt=""/>
                                    <div className="product-info">
                                        <p className="mb-0">{itemProduct.title}</p>
                                        <p className="mb-0">SKU
                                            : {itemProduct?.variants !== null && itemProduct.variants[0]?.sku}</p>
                                        <div className="d-flex justify-content-center custom-icons">
                                            <p>{itemProduct.status === "active" ? "Active" : itemProduct.status === "archive" ? "Archived" : "Draft"}</p>
                                            <span
                                                className={itemProduct.status === "active" ? "active-circle" : itemProduct.status === "archive" ? "archive-circle" : "draft-circle"}> </span>
                                        </div>
                                    </div>

                                    <input
                                        onChange={() => handleOnSelectProducts(itemProduct)}
                                        id={itemProduct.id}
                                        checked={
                                            selectedProducts.findIndex(
                                                (item) => item.id === itemProduct.id
                                            ) !== -1
                                        }
                                        type="checkbox"
                                    />

                                    <span className="tick-product"></span>
                                </label>
                            );
                        })}

                    </div> :

                    <div className="no-data">
                        <p>No Products Found</p>
                    </div>


                }


            </div>


            <div className="footer">
                {indicator ? <Loader/> : ""}
                <button
                    // type="submit"
                    className="btn btn-lg btn-red-color py-3 px-2 btn-block max-400 mx-auto radius-8"
                    onClick={handleSelected}
                >
                    {selectedProducts.length === 0 ? "Back" : "Add Products"}
                </button>
            </div>
        </div>


    );
};

export default ProductList;
